import { withAITracking } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import { AppFunctions, AppState } from "../App";
import { reactPlugin } from "../AppInsights";
import BaseButton from "../components/Button/BaseButton";
import ErrorBoundary from "../components/ErrorBoundary";
import { InformationDetail } from "../components/InformationDetail";
import ReservationCreation from "../components/ReservationCreation";
import ReservationDetail from "../components/ReservationDetail";
import { addMessage } from "../components/ToasterHandler/useToasterHandler";
import { ViewTopSpace } from "../components/ViewTopSpace";
import { causeCommands, processCause } from "../controller/CauseController";
import { getEventsForReservation } from "../controller/EventController";
import {
  cancelReservation,
  confirmReservation,
  deleteReservation,
  guestLeftReservation,
  putNewReservation,
  rescheduleReservation,
  reservationCommands,
  updateGuestAmountOnReservation,
  updateReservation,
  walkedInWithoutReservation,
  walkedInWithReservation,
} from "../controller/ReservierungDataController";
import { assignTablesToReservation, tableCommands, unassignTableToReservation } from "../controller/TableController";
import DailyInformation from "../Informationen/components/DailyInformation";
import { Cause } from "../models/Cause";
import { Email } from "../models/Email";
import { handledProcessCommand } from "../models/Queue";
import { NewReservation, Reservation, walkedInConfirmation } from "../models/Reservation";
import { Table } from "../models/Table";
import ReservierungenView from "./Reservierungen/ReservierungenView";

export interface ReservierungMobileProps extends AppState, AppFunctions {}

export interface ReservierungMobileState {
  activeTab: possibleReservierungMobileTabs;
  selectedReservation: Reservation | undefined;
  isDisplaying_CreateReservation: boolean;
  isDisplaying_ReservationDetail: boolean;
  isDrawerVisible: boolean;
  isCreatingReservation: boolean;
  isUpdatingReservation: boolean;
  isReservationDetailLocked: boolean;
  tempId: string;
  incomingReservation: AppState["appShouldCreateReservation"];
  incomingReservationDetail: AppState["appShouldShowReservationDetail"];
  loadingItems: Reservation[];
}

type possibleReservierungMobileTabs = "UnitTesting" | "GezeitenLaAmarone";

class ReservierungMobile extends React.Component<ReservierungMobileProps, ReservierungMobileState> {
  timeout: ReturnType<typeof setTimeout> | null;
  constructor(props: ReservierungMobileProps) {
    super(props);
    this.state = {
      activeTab: this.props.appRestaurantId as possibleReservierungMobileTabs,
      selectedReservation: undefined,
      isDisplaying_CreateReservation: this.props.appShouldCreateReservation ? true : false,
      isDisplaying_ReservationDetail: !!this.props.appShouldShowReservationDetail,
      isDrawerVisible: this.props.appShouldCreateReservation ? true : false,
      isCreatingReservation: false,
      isReservationDetailLocked: false,
      tempId: "",
      isUpdatingReservation: false,
      incomingReservation: this.props.appShouldCreateReservation,
      incomingReservationDetail: this.props.appShouldShowReservationDetail,
      loadingItems: [],
    };
    this.timeout = null;
  }

  componentDidMount() {
    this.props.setAppState("appHeadTitle", undefined);
    if (this.props.appShouldCreateReservation) {
      this.props.releaseNewReservationPrimer();
    }

    if (this.props.appShouldShowReservationDetail) {
      this._handleReservationDetail(this.props.appShouldShowReservationDetail);
    }
    this._subscribeToQueue();
    // if (this.props.appWebSocket) {
    //   this.props.appWebSocket.addEventListener("message", this._handleIncomingCall);
    // }
    this.timeout = setTimeout(() => {
      this.reloadReservations(this.props.appReservationDate);
    }, 1);
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
    // if (this.props.appWebSocket) {
    //   this.props.appWebSocket.removeEventListener("message", this._handleIncomingCall);
    // }
  }

  componentDidUpdate(prevprops: ReservierungMobileProps, prevState: ReservierungMobileState) {
    if (this.props.appShouldCreateReservation !== null) {
      this.setState(
        {
          isDisplaying_CreateReservation: true,
          isDisplaying_ReservationDetail: false,
          isDrawerVisible: true,
          incomingReservation: this.props.appShouldCreateReservation,
        },
        this.props.releaseNewReservationPrimer
      );
    }
    if (prevprops.appReservations !== this.props.appReservations) {
      if (this.state.selectedReservation) {
        this.setState((cs) => ({
          selectedReservation: cs.selectedReservation
            ? this.props.appReservations.find((item) => item.id === cs.selectedReservation!.id) ||
              cs.selectedReservation
            : undefined,
        }));
      }
    }
  }

  _handleReservationDetail = async (res: Reservation) => {
    try {
      await this.props.setAppState("appReservationDate", new Date(res.dateOfArrival));
      this.setState(
        {
          selectedReservation: res,
          isDisplaying_ReservationDetail: true,
          isDisplaying_CreateReservation: false,
        },
        () => {
          this._handleDrawerToggle(true);
        }
      );
      this.props.releaseReservationDetailPrimer();
    } catch (error) {
      throw error;
    }
  };

  _subscribeToQueue = () => {
    reservationCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp) => {
          if (resp) {
            try {
              const res =
                resp && resp.id
                  ? this.props.appReservations.find((res) => res.id === resp.id + "")
                  : this.props.appReservations.find((res) => res.id === resp + "");
              if (res) {
                return this._setItemLoading(res, false).then(() => {});
              }
              return;
            } catch (error) {
              console.error(error);
            }
          }
        })
    );
    tableCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp) => {
          if (resp) {
            try {
              const res =
                resp && resp.id
                  ? this.props.appReservations.find((res) => res.id === resp.id + "")
                  : this.props.appReservations.find((res) => res.id === resp + "");
              if (res) {
                return this._setItemLoading(res, false).then(() => {});
              }
              return;
            } catch (error) {
              console.error(error);
            }
          }
        })
    );
    causeCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp: Cause) => {
          try {
            this.props.appLoadCauses();
            const res =
              resp && resp.id && resp.reservationId
                ? this.props.appReservations.find((res) => res.id === resp.reservationId + "")
                : this.props.appReservations.find((res) => res.id === resp + "");
            if (res) {
              return this._setItemLoading(res, false).then(() => {});
            }
            return;
          } catch (error) {
            console.error(error);
          }
        })
    );
  };

  _mergeReservation = (reservation: Reservation) => {
    return new Promise<void>((resolve, reject) => {
      let oldReservations = this.props.appReservations || [];
      if (oldReservations.length > 0) {
      }
      this.props
        .setAppState("appReservations", [...oldReservations, reservation])
        .then(resolve)
        .catch((error) => {
          console.error("_mergeReservation", error);
          resolve();
        });
    });
  };

  _updateInList = (reservation: Reservation | undefined, remove?: boolean) => {
    if (reservation !== undefined && reservation !== null) {
      return new Promise<void>((resolve, reject) => {
        let oldReservations = this.props.appReservations || [];
        if (oldReservations.length > 0 && !remove) {
          oldReservations = oldReservations.map((item) => (item.id === reservation.id ? reservation : item));
        } else {
          oldReservations = oldReservations.filter((item) => item.id === reservation.id);
        }
        this.props
          .setAppState("appReservations", oldReservations)
          .then(resolve)
          .catch((error) => {
            console.error(error);
            resolve();
          });

        this.setState((cs) => ({
          selectedReservation: cs.selectedReservation
            ? oldReservations.find((res) => cs.selectedReservation!.id === res.id)
            : undefined,
        }));
      });
    } else return null;
  };

  reloadReservations = async (date?: Date) => {
    try {
      this.props.appLoadReservations(date);
      this.props.appLoadTables();
      return;
    } catch (error) {
      throw error;
    }
  };

  _recreateReservation = async (oldReservation: Reservation) => {
    try {
      const tempReservation = { ...oldReservation };
      await deleteReservation(this.props.appRestaurantId, tempReservation);
      const newRes = await putNewReservation(this.props.appRestaurantId, tempReservation);
      return newRes;
    } catch (error) {
      throw error;
    }
  };

  _rescheduleReservation = async (oldReservation: Reservation, isoDate: string) => {
    try {
      const tempReservation = { ...oldReservation };
      const mock = await rescheduleReservation(this.props.appRestaurantId, tempReservation, isoDate);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _updateGuestAmountOfReservation = async (oldReservation: Reservation, guestAmount: Reservation["guestAmount"]) => {
    try {
      const tempReservation = { ...oldReservation };
      const mock = await updateGuestAmountOnReservation(this.props.appRestaurantId, tempReservation, guestAmount);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _leaveReservation = async (
    oldReservation: Reservation,
    leaveOptions: {
      satisfaction: Reservation["satisfaction"];
      leaveNotice: Reservation["leaveNotice"];
      leaveDateTime: Reservation["leaveDateTime"];
    }
  ) => {
    try {
      const tempReservation = { ...oldReservation, ...leaveOptions };
      const mock = await guestLeftReservation(this.props.appRestaurantId, tempReservation);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _setIsReservationDetailLocked = (bool: boolean) =>
    new Promise<void>((resolve, reject) => {
      try {
        this.setState(
          {
            isReservationDetailLocked: bool,
          },
          resolve
        );
      } catch (error) {
        throw reject(error);
      }
    });

  _handleDrawerToggle = (bool?: boolean) =>
    new Promise<void>((resolve, reject) => {
      try {
        this.setState(
          (currentState) => ({
            isDrawerVisible: bool !== undefined ? bool : !currentState.isDrawerVisible,
          }),
          () => {
            setTimeout(resolve, 210);
          }
        );
      } catch (error) {
        throw reject(error);
      }
    });

  _setItemLoading = (reservation: Reservation, isLoading: boolean) =>
    new Promise<void>(async (resolve, reject) => {
      try {
        this.setState(
          (cs) => {
            let tempItems: ReservierungMobileState["loadingItems"] = [];
            if (isLoading) {
              tempItems = [...cs.loadingItems, reservation];
            } else {
              tempItems = [...cs.loadingItems].filter((item) => item.id !== reservation.id);
            }
            return {
              loadingItems: tempItems,
              selectedReservation:
                cs.selectedReservation !== undefined
                  ? this.props.appReservations.find((item) => item.id === cs.selectedReservation!.id)
                  : undefined,
            };
          },
          () => {
            if (!isLoading) {
              try {
                // console.log("itemloading", "reloading reservations");
                // this.reloadReservations(this.props.appReservationDate, undefined);
                return resolve();
              } catch (error) {
                throw error;
              }
            } else {
              return resolve();
            }
          }
        );
        if (isLoading) {
          await this._handleCloseDrawer();
          return resolve();
        }
      } catch (error) {
        throw reject(error);
      }
    });

  _handleTableAssignment = async (reservation: Reservation, tables: Table[]) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await assignTablesToReservation(this.props.appRestaurantId, tables, reservation);
      return this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleTableUnassignment = async (reservation: Reservation) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await unassignTableToReservation(this.props.appRestaurantId, reservation);
      return this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleCancelReservation = async (reservation: Reservation, reason: string, reasonPhrase: string) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await cancelReservation(this.props.appRestaurantId, reservation, reason, reasonPhrase);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleConfirmReservation = async (reservation: Reservation, shouldSendEmail: boolean, email?: Email) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await confirmReservation(this.props.appRestaurantId, reservation, shouldSendEmail, email);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleWalkedInWithReservation = async (
    reservation: Reservation,
    walkedInConfirmation: walkedInConfirmation,
    tables: Table[]
  ) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await walkedInWithReservation(this.props.appRestaurantId, reservation, walkedInConfirmation, tables);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleUpdateReservation = async (reservation: Reservation) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await updateReservation(this.props.appRestaurantId, reservation);
      this._updateInList(mock);
      return;
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleRescheduleReservation = async (reservation: Reservation, isoDate: string) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._rescheduleReservation(reservation, isoDate);
      const isDifferentDate = isoDate.slice(0, 10) !== this.props.appReservationDate.toISOString().slice(0, 10);
      await this._updateInList(mock, isDifferentDate);
      if (isDifferentDate) {
        this.props.setAppState("appReservationDate", new Date(isoDate)).then(() => {
          this._setItemLoading(reservation, false);
        });
      }
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleGuestAmountUpdateOfReservation = async (reservation: Reservation, guestAmount: Reservation["guestAmount"]) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._updateGuestAmountOfReservation(reservation, guestAmount);
      this._updateInList(mock);
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleLeave = async (
    reservation: Reservation,
    leaveOptions: {
      satisfaction: Reservation["satisfaction"];
      leaveNotice: Reservation["leaveNotice"];
      leaveDateTime: Reservation["leaveDateTime"];
    }
  ) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._leaveReservation(reservation, leaveOptions);
      this._updateInList(mock);
      return;
    } catch (error) {
      throw error;
    }
  };

  _checkIsReservationDetailLocked = () =>
    this.state.loadingItems.some((entry) => {
      if (this.state.selectedReservation) {
        return entry.id === this.state.selectedReservation.id;
      } else return false;
    });

  _handleCloseDrawer = async () => {
    try {
      this.setState({
        isDisplaying_CreateReservation: false,
        isDisplaying_ReservationDetail: false,
        incomingReservation: null,
        selectedReservation: undefined,
      });
      await this._handleDrawerToggle(false);
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleReservationCreation = async (
    newReservation: NewReservation,
    cause?: Cause,
    date?: Date,
    shouldConfirm?: boolean,
    isWalkIn?: boolean,
    createEmail?: boolean,
    email?: Email
  ) => {
    try {
      try {
        const response = isWalkIn
          ? await walkedInWithoutReservation(this.props.appRestaurantId, newReservation)
          : await putNewReservation(this.props.appRestaurantId, newReservation, shouldConfirm, email, createEmail);
        const causeResponse = cause
          ? await processCause(this.props.appRestaurantId, { ...cause, reservationId: response.id + "" }, true)
          : null;

        if (cause) {
          this.props.appLoadCauses();
        }

        if (date) {
          this.props.appLoadSummary(date.getFullYear(), date.getMonth() + 1);
          await this.props.appSetDate(date.toISOString().slice(0, 10));
          await this.reloadReservations(date);
        } else {
          this.props.appLoadSummary(
            this.props.appReservationDate.getFullYear(),
            this.props.appReservationDate.getMonth() + 1
          );
          await this.reloadReservations(this.props.appReservationDate);
        }
        const resp = {
          reservation: response,
          cause: causeResponse,
        };
        return resp;
      } catch (error) {
        const errorObj = { caller: "handleCreateClick", error: error };
        addMessage({
          title: "Erstellung",
          text: `Fehler\r\nErstellung Fehlgeschlagen\r\n\r\n\r\n${
            "message" in (error as any) ? (error as any).message : (error as any)
          }\r\n\r\n${"stack" in (error as any) ? (error as any).stack : ""}`,
          color: "red",
          icon: "ExclamationCircleIcon",
          delay: 6e4,
        });
        throw errorObj;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  _handleDrawerCreationCreate = async (
    res: NewReservation,
    cause: Cause | undefined,
    date: Date | undefined,
    shouldConfirm: boolean | undefined,
    isWalkIn: boolean | undefined,
    createEmail?: boolean,
    email?: Email
  ) => {
    await this._handleCloseDrawer();
    this.setState({
      isCreatingReservation: true,
    });
    await this._handleReservationCreation(res, cause, date, shouldConfirm, isWalkIn, createEmail, email);
    this.setState({
      isCreatingReservation: false,
    });
  };

  _getDrawerCreationChannel = (inc: ReservierungMobileState["incomingReservation"]) =>
    inc ? (inc ? (inc.email ? "Email" : inc.phoneNumber ? "Telephone" : "Direct") : "Direct") : undefined;

  _getDrawerStyle = (isVisible: boolean) => ({
    transitionDuration: "150ms",
    transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
    right: "0%",
    transform: `translate(${isVisible ? "0px" : "101vw"}, 0px)`,
    backgroundColor: "rgba(255,255,255,0.85)",
  });

  _getAmounts = (res: AppState["appReservations"]) =>
    res.reduce(
      (a, b) => {
        if (!["0", "5"].some((numb) => +b.state === +numb)) {
          const { adultAmount, childAmount } = b.guestAmount;
          return [a[0] + adultAmount, a[1] + childAmount];
        } else return a;
      },
      [0, 0]
    );

  _handleReservationTableClick = (res: Reservation | null) => {
    if (res)
      this.setState(
        {
          selectedReservation: this.props.appReservations.find((reservations) => reservations.id === res.id),
          isDisplaying_ReservationDetail: true,
          isDisplaying_CreateReservation: false,
        },
        () => {
          this._handleDrawerToggle(true);
        }
      );
  };

  _handleReservationTableEmptyClick = () => {
    this.setState(
      {
        isDisplaying_ReservationDetail: false,
        isDisplaying_CreateReservation: true,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  _handleGetEventsForDetail = async (reservation: Reservation) => {
    try {
      const events = await getEventsForReservation(this.props.appRestaurantId, reservation);
      if (events.events && this.props.appEvents && events.events[0] && events.events[0].id) {
        if (this.props.appEvents.filter((ev) => ev.id === events.events[0].id).length !== events.events.length) {
          this.props.appLoadEvents();
          return events;
        } else {
          return events;
        }
      } else return events;
    } catch (error) {
      throw error;
    }
  };

  _handleAddButtonClick = () => {
    this.setState(
      {
        isDisplaying_ReservationDetail: false,
        isDisplaying_CreateReservation: true,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  render() {
    const [amountOfAdults, amountOfChildren] = this._getAmounts(this.props.appReservations);
    return (
      <>
        <ErrorBoundary>
          <div
            className={`absolute flex flex-1 flex-col flex-shrink-0 w-full h-full max-h-full min-h-0 z-40 shadow-md top-0 overflow-hidden`}
            style={this._getDrawerStyle(this.state.isDrawerVisible)}
          >
            <div className="fixed top-2 right-10 left-auto bg-red-500 text-white px-4 py-1 rounded">
              <button onClick={this._handleCloseDrawer}>schließen</button>
            </div>
            <div className="relative flex flex-1 flex-col flex-shrink-0 w-full h-full min-h-0 max-h-full z-10">
              {this.state.isDisplaying_CreateReservation ? (
                <ReservationCreation
                  appIsStandalone={this.props.appIsStandalone}
                  close={this._handleCloseDrawer}
                  appColors={this.props.appColors}
                  create={this._handleDrawerCreationCreate}
                  getSummary={this.props.appGetSummary}
                  isCreating={this.state.isCreatingReservation}
                  restaurantId={this.props.appRestaurantId}
                  email={this.state.incomingReservation ? this.state.incomingReservation.email : undefined}
                  phoneNumber={this.state.incomingReservation ? this.state.incomingReservation.phoneNumber : undefined}
                  requestChannel={this._getDrawerCreationChannel(this.state.incomingReservation)}
                  incomingReservation={this.state.incomingReservation || undefined}
                  appSummary={this.props.appSummary}
                  currentDate={this.props.appReservationDate}
                  appGetSummary={this.props.appGetSummary}
                  setAppState={this.props.setAppState}
                ></ReservationCreation>
              ) : null}
              {this.state.isDisplaying_ReservationDetail &&
              (this.state.selectedReservation || this.state.incomingReservationDetail) ? (
                <ReservationDetail
                  reservation={
                    (this.props.appReservations.find(
                      (c) => (this.state.selectedReservation || this.state.incomingReservationDetail)?.id === c.id
                    ) ||
                      this.state.selectedReservation ||
                      this.state.incomingReservationDetail)!
                  }
                  appIsMobile={this.props.appIsMobile}
                  appIsStandalone={this.props.appIsStandalone}
                  close={this._handleCloseDrawer}
                  assignTableToReservation={this._handleTableAssignment}
                  unassignTableToReservation={this._handleTableUnassignment}
                  tableList={this.props.appTables}
                  appColors={this.props.appColors}
                  updateReservation={this._handleUpdateReservation}
                  handleCancel={this._handleCancelReservation}
                  handleConfirm={this._handleConfirmReservation}
                  handleReschedule={this._handleRescheduleReservation}
                  handleWalkedInWithReservation={this._handleWalkedInWithReservation}
                  handleLeave={this._handleLeave}
                  handleGuestAmountChange={this._handleGuestAmountUpdateOfReservation}
                  getEventsForReservation={this._handleGetEventsForDetail}
                  isLocked={false}
                  appEvents={this.props.appEvents}
                  appReservations={this.props.appReservations}
                  appSummary={this.props.appSummary}
                  getSummary={this.props.appGetSummary}
                  getEmail={this.props.getEmail}
                  sendEmail={this.props.sendEmail}
                  setNewReservationPrimer={this.props.setNewReservationPrimer}
                  createBankett={this.props.createBankett}
                ></ReservationDetail>
              ) : null}
            </div>
          </div>
          <ViewTopSpace
            appColors={this.props.appColors}
            height="12"
            backgroundIcon="BookmarkIcon"
            backgroundIconProps={{
              width: "35%",
              height: "50%",
            }}
          >
            <div className={`relative flex justify-betweeb items-center h-full w-full`}>
              <div className="inline-flex flex-col justify-start items-start left-0 space-y-1">
                <InformationDetail appColors={this.props.appColors} icon="BookmarkIcon">
                  {this.props.appReservations.filter((res) => !(+res.state === 5 || +res.state === 0)).length}
                </InformationDetail>

                <InformationDetail appColors={this.props.appColors} icon="UsersIcon">
                  {`${amountOfAdults} ${amountOfChildren ? "+ " + amountOfChildren : ""}`}
                </InformationDetail>
              </div>
              <div className="inline-flex flex-row-reverse justify-end items-start left-0 space-x-1 space-x-reverse ml-auto">
                <BaseButton
                  identifier="Reservierungen_Mobile_AddRes"
                  onClick={this._handleAddButtonClick}
                  icon="PlusIcon"
                  disabled={this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]}
                  className=""
                  paddingOverwrite="p-1"
                  iconSizeOverwrite="w-7 h-7"
                />
                <BaseButton
                  identifier="Reservierungen_Mobile_Refresh"
                  onClick={() => this.reloadReservations()}
                  icon="RefreshIcon"
                  disabled={this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]}
                  className="group"
                  paddingOverwrite="p-1"
                  iconClass={`${
                    this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]
                      ? "animate-spin"
                      : ""
                  }`}
                  iconSizeOverwrite="w-7 h-7"
                />
                <DailyInformation
                  className="h-full aspect-square rounded-md shadow-sm shadow-inherit"
                  date={this.props.appReservationDate}
                />
              </div>
            </div>
          </ViewTopSpace>
          <div className={`flex flex-col w-full h-full min-h-full px-1 bg-primary-700 overflow-hidden`}>
            <div className="relative flex flex-1 w-full h-full min-h-0 bg-white overflow-hidden rounded-t-lg">
              <div className="relative flex flex-2 w-full h-full flex-col border-r max-w-full overflow-auto rounded-t-lg">
                <div
                  className={`absolute top-1 right-0 mr-3 bg-blue-500 rounded text-sm font-semibold tracking-wide z-50 text-blue-50 px-2 pointer-events-none select-none transition-opacity ease-in-out duration-150 ${
                    this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]
                      ? "opacity-100"
                      : "opacity-0"
                  }`}
                >
                  {this.props.appIsPulling["Reservations"] && this.props.appIsLoading["Reservations"]
                    ? "Beides"
                    : this.props.appIsPulling["Reservations"]
                    ? "Server"
                    : this.props.appIsLoading["Reservations"]
                    ? "Datenbank"
                    : "??"}
                </div>

                <ReservierungenView
                  reservations={this.props.appReservations}
                  loadingReservations={this.state.loadingItems}
                  emptyClick={this._handleReservationTableEmptyClick}
                  onReservierungClick={this._handleReservationTableClick}
                  selectedReservation={this.state.selectedReservation}
                  isOpaque={this.props.appIsPulling.Reservations}
                ></ReservierungenView>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </>
    );
  }
}

export default withAITracking(
  reactPlugin,
  ReservierungMobile,
  "ReservierungMobile",
  "flex flex-col w-full h-full min-h-0"
);
