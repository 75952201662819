import * as React from "react";
import { useParams } from "react-router";
import { AppFunctions, AppState } from "../App";
import ErrorBoundary from "../components/ErrorBoundary";
import { InformationDetail } from "../components/InformationDetail";
import { ViewTopSpace } from "../components/ViewTopSpace";
import { causeCommands, processCause } from "../controller/CauseController";
import { getEventsForReservation } from "../controller/EventController";
import {
  cancelReservation,
  confirmReservation,
  deleteReservation,
  guestLeftReservation,
  putNewReservation,
  rescheduleReservation,
  updateGuestAmountOnReservation,
  updateReservation,
  walkedInWithoutReservation,
  walkedInWithReservation,
  reservationCommands,
} from "../controller/ReservierungDataController";
import { assignTablesToReservation, tableCommands, unassignTableToReservation } from "../controller/TableController";
import { Cause } from "../models/Cause";
import { handledProcessCommand } from "../models/Queue";
import { NewReservation, Reservation, walkedInConfirmation } from "../models/Reservation";
import { Table } from "../models/Table";
import { Email } from "../models/Email";
import { SummaryTable } from "../models/Summary";
import ReservationDetail from "../components/ReservationDetail";
import ReservationCreation from "../components/ReservationCreation";
import ReservierungenView from "./Reservierungen/ReservierungenView";
import DateTable from "../components/DateTable";
import TischeView from "./Tische/TischeView";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/outline";
import { LogoutIcon, RefreshIcon } from "@heroicons/react/solid";
import NachrichtenView from "./Nachrichten/NachrichtenView";
import { NachrichtenViewEvent } from "./Nachrichten/NachrichtenViewModel";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import { useNavigate } from "react-router-dom";
import DailyInformation from "../Informationen/components/DailyInformation";
import { addMessage } from "../components/ToasterHandler/useToasterHandler";

const Wrapper = (props: Pick<ReservierungTabletProps, keyof (AppState & AppFunctions)>) => {
  const { activeView, causeToSelect } = useParams<{ activeView?: string; causeToSelect?: string }>();
  const navigate = useNavigate();

  const params: any = React.useMemo(
    () => ({ params: { activeView, causeToSelect } } as any),
    [activeView, causeToSelect]
  );

  const history: any = React.useRef({ push: navigate });

  const memoized = React.useMemo(
    () => <ReservierungTablet {...props} match={params} history={history.current}></ReservierungTablet>,
    [params, props]
  );

  return memoized;
};

export type ReservierungTabletProps = AppState &
  AppFunctions & {
    match: { params: { activeView?: string; causeToSelect?: string } };
    history: { push: (str: string) => void };
  };

export interface ReservierungTabletState {
  activeTab: possibleReservierungTabletTabs;
  selectedReservation: Reservation | undefined;
  activeViewIndex: number;
  isDisplaying_CreateReservation: boolean;
  isDisplaying_ReservationDetail: boolean;
  isDisplaying_TischeView: boolean;
  isDrawerVisible: boolean;
  isCreatingReservation: boolean;
  isUpdatingReservation: boolean;
  isReservationDetailLocked: boolean;
  tempId: string;
  incomingReservation: AppState["appShouldCreateReservation"];
  incomingReservationDetail: AppState["appShouldShowReservationDetail"];
  loadingItems: Reservation[];
  shouldCreateWalkIn: boolean;
}

type possibleReservierungTabletTabs = "UnitTesting" | "GezeitenLaAmarone";

class ReservierungTablet extends React.Component<ReservierungTabletProps, ReservierungTabletState> {
  timeout: ReturnType<typeof setTimeout> | null;
  constructor(props: ReservierungTabletProps) {
    super(props);
    this.state = {
      activeTab: this.props.appRestaurantId as possibleReservierungTabletTabs,
      selectedReservation: undefined,
      activeViewIndex: 2,
      isDisplaying_CreateReservation: this.props.appShouldCreateReservation ? true : false,
      isDisplaying_ReservationDetail: false,
      isDisplaying_TischeView: false,
      isDrawerVisible: this.props.appShouldCreateReservation ? true : false,
      isCreatingReservation: false,
      isReservationDetailLocked: false,
      tempId: "",
      isUpdatingReservation: false,
      incomingReservation: this.props.appShouldCreateReservation,
      incomingReservationDetail: this.props.appShouldShowReservationDetail,
      loadingItems: [],
      shouldCreateWalkIn: false,
    };
    this.timeout = null;
  }

  componentDidMount() {
    console.log("match", this.props.match);
    this.props.setAppState("appHeadTitle", undefined);
    console.log(this.props.match.params);
    if (this.props && this.props.match?.params && this.props.match.params.activeView === undefined) {
      this._handleViewChange(3);
    } else if (this.props && this.props.match?.params) {
      this._handleViewChange(+(this.props.match.params.activeView ?? 3));
    }
    if (this.props && this.props.appShouldCreateReservation) {
      this.props.releaseNewReservationPrimer();
    }
    this._subscribeToQueue();
    // if (this.props.appWebSocket) {
    //   this.props.appWebSocket.addEventListener("message", this._handleIncomingCall);
    // }
    this.timeout = setTimeout(() => {
      this.reloadReservations(this.props.appReservationDate);
    }, 1);
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
    // if (this.props.appWebSocket) {
    //   this.props.appWebSocket.removeEventListener("message", this._handleIncomingCall);
    // }
  }

  componentDidUpdate(prevprops: ReservierungTabletProps, prevState: ReservierungTabletState) {
    if (this.props.appShouldCreateReservation !== null) {
      this.setState(
        {
          isDisplaying_CreateReservation: true,
          isDisplaying_ReservationDetail: false,
          shouldCreateWalkIn: false,
          incomingReservation: this.props.appShouldCreateReservation,
        },
        () => {
          this._handleViewChange(4);
          return this.props.releaseNewReservationPrimer();
        }
      );
    }
    if (this.props.appShouldShowReservationDetail !== null) {
      this._handleReservationDetail(this.props.appShouldShowReservationDetail);
    }
    if (prevprops.appReservations !== this.props.appReservations) {
      if (this.state.selectedReservation) {
        this.setState((cs) => ({
          selectedReservation: cs.selectedReservation
            ? this.props.appReservations.find((item) => item.id === cs.selectedReservation!.id) ||
              cs.selectedReservation
            : undefined,
        }));
      }
    }
    if (prevprops?.match?.params && this.props?.match?.params) {
      const { activeView: prevView } = prevprops.match.params;
      const { activeView: newView } = this.props.match.params;
      if (prevView !== newView || (newView && this.state.activeViewIndex !== +newView)) {
        this.setState((cs) => ({
          activeViewIndex: +(newView ?? cs),
          shouldCreateWalkIn: false,
        }));
      }
    }
  }

  _handleViewChange = (view: number) => {
    this.setState(
      {
        activeViewIndex: view,
      },
      () => {
        if (this.props?.match?.params) {
          const { causeToSelect } = this.props?.match?.params;
          this.props.history.push(`/Reservierung/${view}/${causeToSelect ?? ""}`);
        }
      }
    );
  };

  _handleReservationDetail = async (res: Reservation) => {
    try {
      this.props.releaseReservationDetailPrimer();
      await this.props.setAppState("appReservationDate", new Date(res.dateOfArrival));
      this.setState(
        {
          selectedReservation: res,
          isDisplaying_ReservationDetail: true,
          isDisplaying_CreateReservation: false,
        },
        () => {
          this._handleViewChange(4);
          this._handleDrawerToggle(true);
        }
      );
    } catch (error) {
      throw error;
    }
  };

  _subscribeToQueue = () => {
    reservationCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp) => {
          if (resp) {
            try {
              const res =
                resp && resp.id
                  ? this.props.appReservations.find((res) => res.id === resp.id + "")
                  : this.props.appReservations.find((res) => res.id === resp + "");
              if (res) {
                return this._setItemLoading(res, false).then(() => {});
              }
              return;
            } catch (error) {
              console.error(error);
            }
          }
        })
    );
    tableCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp) => {
          if (resp) {
            try {
              const res =
                resp && resp.id
                  ? this.props.appReservations.find((res) => res.id === resp.id + "")
                  : this.props.appReservations.find((res) => res.id === resp + "");
              if (res) {
                return this._setItemLoading(res, false).then(() => {});
              }
              return;
            } catch (error) {
              console.error(error);
            }
          }
        })
    );
    causeCommands.map(
      (command) =>
        (handledProcessCommand[command] = (resp: Cause) => {
          try {
            this.props.appLoadCauses();
            const res =
              resp && resp.id && resp.reservationId
                ? this.props.appReservations.find((res) => res.id === resp.reservationId + "")
                : this.props.appReservations.find((res) => res.id === resp + "");
            if (res) {
              return this._setItemLoading(res, false).then(() => {});
            }
            return;
          } catch (error) {
            console.error(error);
          }
        })
    );
  };

  _mergeReservation = (reservation: Reservation) => {
    return new Promise<void>((resolve, reject) => {
      let oldReservations = this.props.appReservations || [];
      if (oldReservations.length > 0) {
      }
      this.props
        .setAppState("appReservations", [...oldReservations, reservation])
        .then(resolve)
        .catch((error) => {
          console.error("_mergeReservation", error);
          resolve();
        });
    });
  };

  _updateInList = (reservation: Reservation | undefined, remove?: boolean) => {
    if (reservation !== undefined && reservation !== null) {
      return new Promise<void>((resolve, reject) => {
        let oldReservations = this.props.appReservations || [];
        if (oldReservations.length > 0 && !remove) {
          oldReservations = oldReservations.map((item) => (item.id === reservation.id ? reservation : item));
        } else {
          oldReservations = oldReservations.filter((item) => item.id === reservation.id);
        }
        this.props
          .setAppState("appReservations", oldReservations)
          .then(resolve)
          .catch((error) => {
            console.error(error);
            resolve();
          });

        this.setState((cs) => ({
          selectedReservation: cs.selectedReservation
            ? oldReservations.find((res) => cs.selectedReservation!.id === res.id)
            : undefined,
        }));
      });
    } else return null;
  };

  reloadReservations = async (date?: Date) => {
    try {
      this.props.appLoadReservations(date);
      this.props.appLoadTables();
      return;
    } catch (error) {
      throw error;
    }
  };

  _recreateReservation = async (oldReservation: Reservation) => {
    try {
      const tempReservation = { ...oldReservation };
      await deleteReservation(this.props.appRestaurantId, tempReservation);
      const newRes = await putNewReservation(this.props.appRestaurantId, tempReservation);
      return newRes;
    } catch (error) {
      throw error;
    }
  };

  _rescheduleReservation = async (oldReservation: Reservation, isoDate: string) => {
    try {
      const tempReservation = { ...oldReservation };
      const mock = await rescheduleReservation(this.props.appRestaurantId, tempReservation, isoDate);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _updateGuestAmountOfReservation = async (oldReservation: Reservation, guestAmount: Reservation["guestAmount"]) => {
    try {
      const tempReservation = { ...oldReservation };
      const mock = await updateGuestAmountOnReservation(this.props.appRestaurantId, tempReservation, guestAmount);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _leaveReservation = async (
    oldReservation: Reservation,
    leaveOptions: {
      satisfaction: Reservation["satisfaction"];
      leaveNotice: Reservation["leaveNotice"];
      leaveDateTime: Reservation["leaveDateTime"];
    }
  ) => {
    try {
      const tempReservation = { ...oldReservation, ...leaveOptions };
      const mock = await guestLeftReservation(this.props.appRestaurantId, tempReservation);
      return mock;
    } catch (error) {
      throw error;
    }
  };

  _setIsReservationDetailLocked = (bool: boolean) =>
    new Promise<void>((resolve, reject) => {
      try {
        this.setState(
          {
            isReservationDetailLocked: bool,
          },
          resolve
        );
      } catch (error) {
        throw reject(error);
      }
    });

  _handleDrawerToggle = (bool?: boolean) =>
    new Promise<void>((resolve, reject) => {
      try {
        this.setState(
          (currentState) => {
            return {
              isDrawerVisible: bool !== undefined ? bool : !currentState.isDrawerVisible,
            };
          },
          () => {
            this._handleViewChange(bool !== undefined ? (bool ? 4 : 3) : this.state.activeViewIndex === 4 ? 3 : 4);
            return resolve();
          }
        );
      } catch (error) {
        throw reject(error);
      }
    });

  _setItemLoading = (reservation: Reservation, isLoading: boolean) =>
    new Promise<void>(async (resolve, reject) => {
      try {
        this.setState(
          (cs) => {
            let tempItems: ReservierungTabletState["loadingItems"] = [];
            if (isLoading) {
              tempItems = [...cs.loadingItems, reservation];
            } else {
              tempItems = [...cs.loadingItems].filter((item) => item.id !== reservation.id);
            }
            return {
              loadingItems: tempItems,
              selectedReservation:
                cs.selectedReservation !== undefined
                  ? this.props.appReservations.find((item) => item.id === cs.selectedReservation!.id)
                  : undefined,
            };
          },
          () => {
            if (!isLoading) {
              try {
                // console.log("itemloading", "reloading reservations");
                // this.reloadReservations(this.props.appReservationDate, undefined);
                return resolve();
              } catch (error) {
                throw error;
              }
            } else {
              return resolve();
            }
          }
        );
        if (isLoading) {
          await this._handleCloseDrawer();
          return resolve();
        }
      } catch (error) {
        throw reject(error);
      }
    });

  _handleTableAssignment = async (reservation: Reservation, tables: Table[]) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await assignTablesToReservation(this.props.appRestaurantId, tables, reservation);
      return this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleTableUnassignment = async (reservation: Reservation) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await unassignTableToReservation(this.props.appRestaurantId, reservation);
      return this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleCancelReservation = async (reservation: Reservation, reason: string, reasonPhrase: string) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await cancelReservation(this.props.appRestaurantId, reservation, reason, reasonPhrase);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleConfirmReservation = async (reservation: Reservation, shouldSendEmail: boolean, email?: Email) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await confirmReservation(this.props.appRestaurantId, reservation, shouldSendEmail, email);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleWalkedInWithReservation = async (
    reservation: Reservation,
    walkedInConfirmation: walkedInConfirmation,
    tables: Table[]
  ) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await walkedInWithReservation(this.props.appRestaurantId, reservation, walkedInConfirmation, tables);
      this._updateInList(mock);
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleUpdateReservation = async (reservation: Reservation) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await updateReservation(this.props.appRestaurantId, reservation);
      this._updateInList(mock);
      return;
    } catch (error) {
      this._setItemLoading(reservation, false);
      throw error;
    }
  };

  _handleRescheduleReservation = async (reservation: Reservation, isoDate: string) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._rescheduleReservation(reservation, isoDate);
      const isDifferentDate = isoDate.slice(0, 10) !== this.props.appReservationDate.toISOString().slice(0, 10);
      await this._updateInList(mock, isDifferentDate);
      if (isDifferentDate) {
        this.props.setAppState("appReservationDate", new Date(isoDate)).then(() => {
          this._setItemLoading(reservation, false);
        });
      }
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleGuestAmountUpdateOfReservation = async (reservation: Reservation, guestAmount: Reservation["guestAmount"]) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._updateGuestAmountOfReservation(reservation, guestAmount);
      this._updateInList(mock);
      return;
    } catch (error) {
      throw error;
    }
  };

  _handleLeave = async (
    reservation: Reservation,
    leaveOptions: {
      satisfaction: Reservation["satisfaction"];
      leaveNotice: Reservation["leaveNotice"];
      leaveDateTime: Reservation["leaveDateTime"];
    }
  ) => {
    try {
      this._setItemLoading(reservation, true);
      const mock = await this._leaveReservation(reservation, leaveOptions);
      this._updateInList(mock);
      return;
    } catch (error) {
      throw error;
    }
  };

  _checkIsReservationDetailLocked = () =>
    this.state.loadingItems.some((entry) => {
      if (this.state.selectedReservation) {
        return entry.id === this.state.selectedReservation.id;
      } else return false;
    });

  _handleCloseDrawer = async () => {
    try {
      this.setState(
        {
          selectedReservation: undefined,
        },
        async () => {
          const timeout = setTimeout(() => {
            this.setState({
              isDisplaying_CreateReservation: false,
              isDisplaying_ReservationDetail: false,
              isDisplaying_TischeView: false,
              incomingReservation: null,
              selectedReservation: undefined,
              shouldCreateWalkIn: false,
            });
          }, 200);
          await this._handleDrawerToggle(false);

          return timeout;
        }
      );
    } catch (error) {
      throw error;
    }
  };

  _handleReservationCreation = async (
    newReservation: NewReservation,
    cause?: Cause,
    date?: Date,
    shouldConfirm?: boolean,
    isWalkIn?: boolean,
    createEmail?: boolean,
    email?: Email
  ) => {
    try {
      try {
        const response = isWalkIn
          ? await walkedInWithoutReservation(this.props.appRestaurantId, newReservation)
          : await putNewReservation(this.props.appRestaurantId, newReservation, shouldConfirm, email, createEmail);
        const causeResponse = cause
          ? await processCause(this.props.appRestaurantId, { ...cause, reservationId: response.id + "" }, true)
          : null;

        if (cause) {
          this.props.appLoadCauses();
          window.dispatchEvent(new CustomEvent(NachrichtenViewEvent.refreshNachrichte));
        }

        if (date) {
          this.props.appLoadSummary(date.getFullYear(), date.getMonth() + 1);
          await this.props.appSetDate(date.toISOString().slice(0, 10));
          this.reloadReservations(date);
        } else {
          this.props.appLoadSummary(
            this.props.appReservationDate.getFullYear(),
            this.props.appReservationDate.getMonth() + 1
          );
          this.reloadReservations(this.props.appReservationDate);
        }
        const resp = {
          reservation: response,
          cause: causeResponse,
        };
        return resp;
      } catch (error) {
        const err: any = error;
        const errorObj = { caller: "handleCreateClick", error: error };
        addMessage({
          title: "Erstellung",
          text: `Fehler\r\nErstellung Fehlgeschlagen\r\n\r\n\r\n${"message" in err ? err.message : err}\r\n\r\n${
            "stack" in err ? err.stack : ""
          }`,
          color: "red",
          icon: "ExclamationCircleIcon",
          delay: 6e4,
        });
        throw errorObj;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  _handleDrawerCreationCreate = async (
    res: NewReservation,
    cause: Cause | undefined,
    date: Date | undefined,
    shouldConfirm: boolean | undefined,
    isWalkIn: boolean | undefined,
    createEmail?: boolean,
    email?: Email
  ) => {
    try {
      this.setState({
        isCreatingReservation: true,
      });
      this._handleReservationCreation(res, cause, date, shouldConfirm, isWalkIn, createEmail, email);
      await this._handleCloseDrawer();
      return;
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        isCreatingReservation: false,
      });
    }
  };

  _getDrawerCreationChannel = (inc: ReservierungTabletState["incomingReservation"]) =>
    inc ? (inc ? (inc.email ? "Email" : inc.phoneNumber ? "Telephone" : "Direct") : "Direct") : undefined;

  _getDrawerStyle = (isVisible: boolean) => ({
    transitionDuration: "200ms",
    transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
    right: "0%",
    transform: `translate(${isVisible ? "0px" : "101vw"}, 0px)`,
    backgroundColor: "rgba(255,255,255,0.85)",
  });

  _getAmounts = (res: AppState["appReservations"]) =>
    res.reduce(
      (a, b) => {
        if (!["0", "5"].some((numb) => +b.state === +numb)) {
          const { adultAmount, childAmount } = b.guestAmount;
          return [a[0] + adultAmount, a[1] + childAmount];
        } else return a;
      },
      [0, 0]
    );

  _handleReservationTableClick = (res: Reservation | null) => {
    if (res) {
      this.setState(
        {
          selectedReservation: this.props.appReservations.find((reservations) => reservations.id === res.id),
          isDisplaying_ReservationDetail: true,
          isDisplaying_CreateReservation: false,
          isDisplaying_TischeView: false,
        },
        () => {
          this._handleDrawerToggle(true);
        }
      );
    }
  };

  _handleReservationTableEmptyClick = () => {
    this.setState(
      {
        isDisplaying_ReservationDetail: false,
        isDisplaying_CreateReservation: true,
        isDisplaying_TischeView: false,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  _handleGetEventsForDetail = async (reservation: Reservation) => {
    try {
      const events = await getEventsForReservation(this.props.appRestaurantId, reservation);
      if (events.events && this.props.appEvents && events.events[0] && events.events[0].id) {
        if (this.props.appEvents.filter((ev) => ev.id === events.events[0].id).length !== events.events.length) {
          this.props.appLoadEvents();
          return events;
        } else {
          return events;
        }
      } else return events;
    } catch (error) {
      throw error;
    }
  };

  _handleAddButtonClick = () => {
    this.setState(
      {
        isDisplaying_ReservationDetail: false,
        isDisplaying_CreateReservation: true,
        isDisplaying_TischeView: false,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  _handleWalkInButtonClick = () => {
    this.setState(
      {
        isDisplaying_ReservationDetail: false,
        isDisplaying_CreateReservation: true,
        isDisplaying_TischeView: false,
        shouldCreateWalkIn: true,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  _displayTischeView = () => {
    this.setState(
      {
        isDisplaying_CreateReservation: false,
        isDisplaying_ReservationDetail: false,
        isDisplaying_TischeView: true,
      },
      () => {
        this._handleDrawerToggle(true);
      }
    );
  };

  _getSelectedOverviewDate = (date: Date) => date.toISOString().slice(0, 10);

  _renderNotificationNumber = () => {
    const newCauses = this.props.appCauses.filter((cau) => cau.state <= 0).length;
    if (newCauses) {
      return (
        <div
          className={`absolute flex justify-center items-center top-0 bg-red-500 text-white w-${
            newCauses >= 10 ? "6" : "5"
          } h-5 text-xs rounded-full z-20 leading-none font-semibold tracking-normal`}
          style={{
            right: newCauses >= 10 ? "-1rem" : "-0.5rem",
            top: "-0.6rem",
          }}
        >
          {newCauses}
        </div>
      );
    } else return null;
  };

  _handleDateTableSelect = (date: string) => {
    this._handleViewChange(3);
    this.props.appSetDate(date);
  };

  _handleReturn = () => {
    this.setState({
      incomingReservation: null,
    });
    return this._handleCloseDrawer();
  };

  _handleReloadReservation = () => this.reloadReservations();

  _handleView = (activeViewIndex: number) => {
    return {};
  };

  render() {
    const [amountOfAdults, amountOfChildren] = this._getAmounts(this.props.appReservations);
    const _nachrichtenProps = {
      ...this.props,
      tabletMode: true,
    };

    const _dateTableProps = {
      size: this.props.appColSize ? ((this.props.appColSize + "") as any) : "3",
      appIsStandalone: this.props.appIsStandalone,
      selectedDate: this._getSelectedOverviewDate(this.props.appReservationDate),
      appColors: this.props.appColors,
      getSummary: this.props.appGetSummary,
      selectDate: this._handleDateTableSelect,
      preCreatedSummary: this.props.appSummary || undefined,
      getCreatedSummary: (summary: SummaryTable | null) =>
        summary ? this.props.setAppState("appSummary", summary) : null,
    };

    const _reservierungsProps = {
      reservations: this.props.appReservations,
      loadingReservations: this.state.loadingItems,
      emptyClick: this._handleReservationTableEmptyClick,
      onReservierungClick: this._handleReservationTableClick,
      selectedReservation: this.state.selectedReservation,
      isOpaque: this.props.appIsPulling.Reservations,
    };

    const _tischeProps = {
      date: this.props.appReservationDate,
      reservations: this.props.appReservations,
      spaceOnBottom: false,
      tables: this.props.appTables,
      mandantId: this.props.appRestaurantId,
      selectedReservation: this.state.selectedReservation,
    };

    const _reservationCreationProps = {
      appIsStandalone: this.props.appIsStandalone,
      // close:this._handleCloseDrawer,
      appColors: this.props.appColors,
      create: this._handleDrawerCreationCreate,
      getSummary: this.props.appGetSummary,
      isCreating: this.state.isCreatingReservation,
      restaurantId: this.props.appRestaurantId,
      email: this.state.incomingReservation ? this.state.incomingReservation.email : undefined,
      phoneNumber: this.state.incomingReservation ? this.state.incomingReservation.phoneNumber : undefined,
      requestChannel: this._getDrawerCreationChannel(this.state.incomingReservation),
      incomingReservation: this.state.incomingReservation || undefined,
      appSummary: this.props.appSummary,
      dateTableSize: this.props.appColSize ? ((this.props.appColSize + "") as any) : "3",
      isWalkIn: this.state.shouldCreateWalkIn,
      currentDate: this.props.appReservationDate,
      appGetSummary: this.props.appGetSummary,
      setAppState: this.props.setAppState,
    };

    const _reservationDetailProps = {
      appIsMobile: this.props.appIsMobile,
      appIsStandalone: this.props.appIsStandalone,
      // close:this._handleCloseDrawer,
      assignTableToReservation: this._handleTableAssignment,
      unassignTableToReservation: this._handleTableUnassignment,
      tableList: this.props.appTables,
      appColors: this.props.appColors,
      reservation: (this.props.appReservations.find(
        (c) => (this.state.selectedReservation || this.state.incomingReservationDetail)?.id === c.id
      ) ||
        this.state.selectedReservation ||
        this.state.incomingReservationDetail)!,
      updateReservation: this._handleUpdateReservation,
      handleCancel: this._handleCancelReservation,
      handleConfirm: this._handleConfirmReservation,
      handleReschedule: this._handleRescheduleReservation,
      handleWalkedInWithReservation: this._handleWalkedInWithReservation,
      handleLeave: this._handleLeave,
      handleGuestAmountChange: this._handleGuestAmountUpdateOfReservation,
      getEventsForReservation: this._handleGetEventsForDetail,
      isLocked: false,
      appEvents: this.props.appEvents,
      appReservations: this.props.appReservations,
      appSummary: this.props.appSummary,
      getSummary: this.props.appGetSummary,
      getEmail: this.props.getEmail,
      sendEmail: this.props.sendEmail,
      setNewReservationPrimer: this.props.setNewReservationPrimer,
      createBankett: this.props.createBankett,
    };

    const _transform = {
      transform: `translate(calc(100vw - ${this.state.activeViewIndex * 50}vw),0vw)`,
    };

    return (
      <>
        <ErrorBoundary>
          {/* <div
            className={`absolute flex flex-1 flex-col flex-shrink-0 w-1/2 h-full z-40 shadow-md top-0 overflow-hidden`}
            style={this._getDrawerStyle(this.state.isDrawerVisible)}
          ></div> */}

          <div
            className={`relative flex justify-start h-full w-full translate-x-0 transition-transform duration-200 ease-in-out`}
            style={_transform}
          >
            <div className={`relative flex flex-col w-1/2 h-full flex-shrink-0 flex-grow-0`}>
              <NachrichtenView {..._nachrichtenProps} />
            </div>
            <div
              className={`relative flex flex-col w-1/2 min-w-0 h-full bg-primary-700 overflow-hidden flex-shrink-0 flex-grow-0`}
            >
              <div className="relative flex w-full h-12 flex-shrink-0 flex-grow-0 justify-start items-center min-w-0">
                <div
                  onClick={() => {
                    this._handleViewChange(this.state.activeViewIndex === 2 ? 3 : 2);
                  }}
                  className={`absolute flex justify-center items-center w-28 h-8 mx-2 bg-white rounded-lg z-10 transition-transform transform duration-200 left-0 ease-in-out text-xl font-semibold tracking-wide text-primary-700`}
                  style={{
                    transform: `translate(${this.state.activeViewIndex === 3 ? "calc(50vw - 8rem)" : "0vw"}, 0px)`,
                  }}
                >
                  <div className={`relative px-2 text-xs w-full inline-flex justify-center items-center`}>
                    {this.props.appReservationDate.toLocaleDateString("de-de", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </div>
                </div>
              </div>
              <div className="flex w-full h-full overflow-auto min-h-0 z-10">
                <DateTable {..._dateTableProps}></DateTable>
              </div>
            </div>
            <div className={`relative flex flex-col w-1/2 h-full overflow-hidden flex-shrink-0 flex-grow-0`}>
              <ViewTopSpace
                appColors={this.props.appColors}
                height={"12"}
                backgroundIcon="BookmarkIcon"
                backgroundIconProps={{ width: "35%", height: "50%" }}
              >
                <div className={`relative flex justify-center items-center h-full w-full`}>
                  <div className="absolute flex flex-col justify-center items-start w-32 left-0 -ml-3">
                    <div className="flex justify-start flex-grow-0">
                      <InformationDetail appColors={this.props.appColors} icon="BookmarkIcon">
                        {this.props.appReservations.filter((res) => !(+res.state === 5 || +res.state === 0)).length}
                      </InformationDetail>
                    </div>
                    <div className="flex justify-start flex-grow-0">
                      <InformationDetail appColors={this.props.appColors} icon="UsersIcon">
                        {`${amountOfAdults} ${amountOfChildren ? "+ " + amountOfChildren : ""}`}
                      </InformationDetail>
                    </div>
                  </div>
                  <div className="absolute top-0 right-0 inline-flex -mr-1 h-full justify-center items-center">
                    {this.state.activeViewIndex !== 4 ? (
                      <>
                        <DailyInformation
                          className="h-9 aspect-square rounded-lg mx-1"
                          date={this.props.appReservationDate}
                        />
                        <div className="relative flex flex-col justify-center items-end w-11 pr-1 right-0">
                          <div
                            onClick={this._handleAddButtonClick}
                            className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-9 h-9 bg-primary-500 shadow-md rounded-lg z-10 ${
                              this.state.isDisplaying_CreateReservation ? "pulseOnce" : ""
                            }`}
                          >
                            <PlusIcon className="w-7 h-7 text-white" />
                          </div>
                        </div>
                        <div className="relative flex flex-col justify-center items-end w-11 pr-1 right-0">
                          <div
                            onClick={this._handleWalkInButtonClick}
                            className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-9 h-9 bg-primary-500 shadow-md rounded-lg z-10 ${
                              this.state.shouldCreateWalkIn ? "pulseOnce" : ""
                            }`}
                          >
                            <LogoutIcon className="w-7 h-7 text-white" />
                          </div>
                        </div>
                        <div className="relative flex flex-col justify-center items-end w-11 pr-1 right-0 ">
                          <div
                            onClick={this._handleReloadReservation}
                            className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-9 h-9 bg-primary-500 shadow-md rounded-lg z-10 ${
                              this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]
                                ? "pulseOnce"
                                : ""
                            }`}
                          >
                            <RefreshIcon
                              className={`h-6 w-6 text-white ${
                                this.props.appIsPulling["Reservations"] || this.props.appIsLoading["Reservations"]
                                  ? "animate-spin"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                        {/* <div className="absolute flex flex-col justify-center items-end w-12 pr-2 right-0 mr-16">
                        <div
                          onClick={this._displayTischeView}
                          className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-9 h-9 bg-primary-500 shadow-md rounded-lg z-10`}
                        >
                          <div className={`h-6 w-6 text-white`}>
                            <IconViewList></IconViewList>
                          </div>
                        </div>
                      </div> */}
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-end w-12 pr-2 right-0">
                        <div
                          onClick={this._handleReturn}
                          className={`flex flex-grow-0 flex-shrink-0 justify-center items-center w-9 h-9 bg-primary-500 shadow-md rounded-lg z-10 ${
                            this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]
                              ? "pulseOnce"
                              : ""
                          }`}
                        >
                          <ArrowLeftIcon className={`h-6 w-6 text-white`} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ViewTopSpace>
              <div className={`flex flex-col w-full h-full px-1 bg-primary-700 overflow-hidden`}>
                <div className="relative flex flex-1 w-full min-h-0 bg-white overflow-hidden rounded-t-lg">
                  <div className="relative flex flex-2 flex-col border-r max-w-full w-full overflow-auto rounded-t-lg">
                    <div
                      className={`absolute top-1 right-0 mr-3 bg-blue-500 rounded text-sm font-semibold tracking-wide z-50 text-blue-50 px-2 pointer-events-none select-none transition-opacity ease-in-out duration-150 ${
                        this.props.appIsLoading["Reservations"] || this.props.appIsPulling["Reservations"]
                          ? "opacity-100"
                          : "opacity-0"
                      }`}
                    >
                      {this.props.appIsPulling["Reservations"] && this.props.appIsLoading["Reservations"]
                        ? "Beides"
                        : this.props.appIsPulling["Reservations"]
                        ? "Server"
                        : this.props.appIsLoading["Reservations"]
                        ? "Datenbank"
                        : "??"}
                    </div>
                    <ReservierungenView {..._reservierungsProps}></ReservierungenView>
                  </div>
                </div>
              </div>
            </div>
            <div className={`relative flex flex-col w-1/2 h-full overflow-hidden flex-shrink-0 flex-grow-0`}>
              {this.state.isDisplaying_TischeView ? <TischeView {..._tischeProps}></TischeView> : null}
              {this.state.isDisplaying_CreateReservation ? (
                <ReservationCreation {..._reservationCreationProps}></ReservationCreation>
              ) : null}
              {this.state.isDisplaying_ReservationDetail &&
              (this.state.selectedReservation || this.state.incomingReservationDetail) ? (
                <ReservationDetail {..._reservationDetailProps}></ReservationDetail>
              ) : null}
            </div>
          </div>
        </ErrorBoundary>
      </>
    );
  }
}

export default withAITracking(reactPlugin, Wrapper, "ReservierungTablet", "flex flex-col w-full h-full min-h-0");
