import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import { createRoot } from "react-dom/client";
import packageJson from "../package.json";
import "./App.css";
import { AppShell } from "./AppShell";
import AuthConfig from "./AuthConfig";

async function registerSW() {
  const env = process.env.NODE_ENV;
  if (!window.location.origin.includes("localhost") && navigator && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    console.log(publicUrl.origin, window.location.origin);
    if (publicUrl.origin !== window.location.origin) {
      return;
    } else {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      navigator.serviceWorker.register(swUrl);
    }
  }
}

const PCA = new PublicClientApplication(AuthConfig.base);

function AppProvider() {
  return (
    <React.StrictMode>
      <MsalProvider instance={PCA}>
        <AppShell />
      </MsalProvider>
      <span className="fixed bottom-3 left-4 px-1 text-white/80 mix-blend-difference font-semibold tracking-wide text-xs z-[100] select-none pointer-events-none">
        v{packageJson.version ?? "??"}
      </span>
    </React.StrictMode>
  );
}

const container = window?.document?.getElementById("root");
if (!container) throw new Error("Konnte root nicht finden");
const root = createRoot(container);
root.render(<AppProvider />);

registerSW();
