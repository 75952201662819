import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { AIReactCustomEvent } from "@microsoft/applicationinsights-react-js/types/useTrackEvent";
import { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { reactPlugin } from "./AppInsights";
import AppInsightsWrapper from "./appInsights/AppInsightsWrapper";
import SettingsProvider, { useSettings } from "./clientApi/SettingsProvider";
import Action from "./clientApi/tracking/Actions";
import ErrorBoundary from "./components/ErrorBoundary";
import { MessageBox } from "./components/MessageBox";
import { useToasterHandler } from "./components/ToasterHandler/useToasterHandler";
import { Device, useDevice } from "./hooks/useDevice";
import { useToggle } from "./hooks/useToggle";
import { UnauthenticatedMainView } from "./views/Unauthenticated/main/UnauthenticatedMainView";

import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  SilentRequest,
} from "@azure/msal-browser";
import AuthConfig from "./AuthConfig";
import DelayedNavigate from "./components/DelayedNavigate";
import AuthenticatedView from "./views/Authenticated/AuthenticatedView";

const generateEmptyClientApiAction = () => {
  const now = new Date();
  const action: Action.Base<Action.Variant.Empty> = {
    type: Action.Variant[Action.Variant.Empty],
    progress: Action.Progress[Action.Progress.Started] as keyof typeof Action.Progress,
    actionName: "generateEmptyClientApiAction",
    triggeredBy: "Code",
    actionData: "Leerlauf",
    information: {
      currentUrl: window?.location?.href ?? "",
      timestamp: now,
      timestampString: now.toISOString(),
      isError: false,
    },
  };
  return action;
};

export const TrackContext = createContext<AIReactCustomEvent<Action.Base<any>>>(undefined as any);

export const AppShell = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const account = useMemo(() => accounts[0] ?? null, [accounts]);

  const loginHint = useMemo(() => {
    const hint = localStorage.getItem("loginHint");
    if (hint) {
      return hint;
    } else if (account) {
      return account.username ?? (account?.idTokenClaims as any)?.emails
        ? (account?.idTokenClaims as any)?.emails[0]
        : undefined;
    } else return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useLayoutEffect(() => {
    if (loginHint) {
      localStorage.setItem("loginHint", loginHint);
    }
  }, [loginHint]);

  const config: SilentRequest = useMemo(
    () =>
      ({
        ...AuthConfig.loginRequest,
        account: account ?? undefined,
        loginHint: loginHint,
        redirectUri: window.location.origin,
      } as SilentRequest),
    [account, loginHint]
  );
  const configRef = useRef(config);
  useEffect(() => {
    configRef.current = config;
  }, [config]);

  const { acquireToken, error, login } = useMsalAuthentication(InteractionType.Silent, config);

  useLayoutEffect(() => {
    if (error) {
      if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect, configRef.current);
      } else {
        login(InteractionType.Redirect, configRef.current);
      }
    }
  }, [error, login]);

  // const { acquireToken } = useAuth();

  const handleTokenResponse = useCallback((tokenResp: AuthenticationResult | null) => {
    try {
      if (tokenResp) {
        const { accessToken } = tokenResp;
        sessionStorage.setItem("token_fallback", accessToken);
        console.log("sende Token an SW");
        const payload = { type: "TOKEN", token: accessToken };
        if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
          const { serviceWorker } = navigator;
          serviceWorker.controller?.postMessage(payload);
        }
      } else throw new Error("Token Response war null");
    } catch (error) {
      console.error(error);
    }
  }, []);

  const sendTokenToServiceWorker = useCallback(async () => {
    try {
      if (inProgress !== InteractionStatus.None) {
        throw new Error("Kein Token angefordert - es wird noch eine Interaction durchgeführt");
      }
      if (account === null || account === undefined) {
        throw new Error("Kein Token angefordert - Account scheint nicht gesetzt zu sein");
      }
      console.log("Prüfe ob Token an SW gesendet werden soll");
      const response = await acquireToken(InteractionType.Silent, configRef.current);
      if (response) handleTokenResponse(response);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect, configRef.current);
      }
      console.error("Konnte Token nicht an ServiceWorker senden", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquireToken, inProgress, account, handleTokenResponse]);

  // const resetExpiredDate = useCallback((date: Date) => {
  //   const eineStunde = 36e5;
  //   const differenz = +Date.now() - +date;
  //   if (differenz >= eineStunde) {
  //     setLastTokenDate(null);
  //   }
  // }, []);

  useEffect(() => {
    if (
      isAuthenticated &&
      account &&
      navigator &&
      "serviceWorker" in navigator &&
      navigator.serviceWorker &&
      inProgress === InteractionStatus.None
    ) {
      const { serviceWorker } = navigator;
      const messageHandler = (event: MessageEvent) => {
        if (event.data.type && event.data.type === "TOKEN_REFRESH") {
          console.log("SW fordert neuen Token");
          sendTokenToServiceWorker();
        }
      };
      serviceWorker.addEventListener("message", messageHandler);
      return () => {
        serviceWorker.removeEventListener("message", messageHandler);
      };
    }
  }, [account, inProgress, isAuthenticated, sendTokenToServiceWorker]);

  useEffect(() => {
    if (isAuthenticated && account && inProgress === InteractionStatus.None) {
      const refreshHandler = () => {
        console.log("Window fordert neuen Token");
        sendTokenToServiceWorker();
      };
      window.addEventListener("TOKEN_REFRESH", refreshHandler);
      return () => {
        window.removeEventListener("TOKEN_REFRESH", refreshHandler);
      };
    }
  }, [account, inProgress, isAuthenticated, sendTokenToServiceWorker]);

  useLayoutEffect(() => {
    if (isAuthenticated && account && inProgress === InteractionStatus.None) {
      console.log("Frage Initialen Token ab");
      sendTokenToServiceWorker();
      console.log("Starte Interval");
      const interval = setInterval(() => {
        console.log("Rufe neuen Token ab oder behalte den Alten");
        sendTokenToServiceWorker();
      }, 6e4 * 1);
      return () => {
        if (interval) {
          console.log("Breche Interval ab");
          clearInterval(interval);
        }
      };
    }
  }, [account, inProgress, isAuthenticated, sendTokenToServiceWorker]);

  const actionRef = useRef<Action.Base<Action.Variant.Empty>>(generateEmptyClientApiAction());
  const trackGenericEvent = useTrackEvent<Action.Base<any>>(reactPlugin, "Client_Action", actionRef.current, false);
  const trackClientApiEvent = useTrackEvent<Action.Base<any>>(reactPlugin, "Client_API", actionRef.current, false);

  const [isUpdateAvailable, , showUpdateMsg, hideUpdateMsg] = useToggle();
  // const [brake, isBraking] = useEmergencyBrake();
  // const onBrake = useCallback(() => {
  //   try {
  //     const ev = new CustomEvent("STORAGE_BLOCK", { detail: true });
  //     window.dispatchEvent(ev);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   brake();
  // }, [brake]);

  const update = useCallback(async () => {
    try {
      if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        const waitingRegistration = registrations.find((c) => c.waiting !== null);
        if (waitingRegistration?.waiting) {
          waitingRegistration.waiting.postMessage({ type: "SKIP_WAITING" });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
      // onBrake();
    } catch (error) {}
  }, []);

  // const fetchManifest = useCallback(async () => {
  //   try {
  //     const resp = await fetch(`${window.location.origin}/manifest.json`, { cache: "no-store" });
  //     if (resp.ok) {
  //       const manifest = await resp.json();
  //       if (manifest && "version" in manifest && manifest.version !== packageJson.version) {
  //         showUpdateMsg();
  //       }
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }, [showUpdateMsg]);

  const checkForUpdate = useCallback(async () => {
    if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
      const reg = await navigator.serviceWorker.ready;
      if (reg) await reg.update();
      if (reg.waiting) {
        showUpdateMsg();
      }
    }
    // fetchManifest();
  }, [showUpdateMsg]);

  useLayoutEffect(() => {
    if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
      const interval = setInterval(checkForUpdate, 6e4);
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [checkForUpdate]);

  useLayoutEffect(() => {
    const fixHeight = () => {
      if ("resizeTo" in window && typeof window.resizeTo === "function") {
        window.resizeTo(window.screen.availWidth, window.screen.availHeight);
      }
    };
    const observer = new MutationObserver(fixHeight);
    const timeout = setTimeout(() => {
      observer.observe(document.documentElement, { subtree: true, attributes: true });
    }, 1e3);
    return () => {
      if (timeout) clearTimeout(timeout);
      observer.disconnect();
    };
  }, []);

  const device = useDevice(1e3);
  const Settings = useSettings();
  const { addMessage } = useToasterHandler();

  useEffect(() => {
    const currentDisplay = SettingsProvider.get("displayMode");
    console.log({ currentDisplay, device });
    if (device === Device.Mobile && currentDisplay !== "Mobile") {
      SettingsProvider.set("displayMode", "Mobile");
    } else if (device === Device.Tablet && currentDisplay !== "Tablet") {
      SettingsProvider.set("displayMode", "Tablet");
    } else if (device === Device.Desktop && currentDisplay !== "Web") {
      SettingsProvider.set("displayMode", "Web");
    }
  }, [device]);

  useEffect(() => {
    const currentDisplay = Settings.displayMode;
    console.log({ currentDisplay, device });
    if (device === Device.Mobile && currentDisplay !== "Mobile") {
      Settings.set("displayMode", "Mobile");
    } else if (device === Device.Tablet && currentDisplay !== "Tablet") {
      Settings.set("displayMode", "Tablet");
    } else if (device === Device.Desktop && currentDisplay !== "Web") {
      Settings.set("displayMode", "Web");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Settings.set, Settings.displayMode, device]);

  const checkDeprecatedUrl = useCallback(() => {
    try {
      const url = document.location.hostname;

      const isBackend = url.toLocaleLowerCase().includes("backend");
      const isKonzept = url.toLocaleLowerCase().includes("konzept");
      const isDeprecated = isBackend || isKonzept;
      Settings.set("isDepreactedUrl", isDeprecated);
    } catch (error) {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Settings.set]);

  useEffect(() => {
    checkDeprecatedUrl();
  }, [checkDeprecatedUrl]);

  useEffect(() => {
    if (Settings.isDepreactedUrl) {
      const goToAppsMkGk = () => {
        window.location.replace("https://www.mk-gk.de/apps.html");
      };

      const showMessage = () => {
        return addMessage({
          color: "error",
          icon: "ShieldExclamationIcon",
          title: "Nicht unterstützte URL",
          text: `Sie nutzen aktuell eine URL die nicht länger unterstützt wird\r\n\r\n${window.location.hostname} ist nicht Prod1 oder Prod2\r\n\r\nBitte rufen Sie mk-gk.de/apps.html auf und wählen Sie dort eine Version`,
          delay: 600000,
          onClick: goToAppsMkGk,
        });
      };
      setTimeout(() => {
        showMessage();
      }, 2500);
      const interval = setInterval(() => showMessage(), 1 * 60 * 1000);
      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [Settings.isDepreactedUrl, addMessage]);

  // const killServiceWorkers = useCallback(async () => {
  //   try {
  //     if (navigator && "serviceWorker" in navigator) {
  //       const regs = await navigator.serviceWorker.getRegistrations();
  //       if (regs.length > 0) {
  //         for (const reg of regs) {
  //           await reg.unregister();
  //         }
  //         const timeout = setTimeout(() => {
  //           window.location.reload();
  //         }, 500);
  //         return timeout;
  //       }
  //     } else return null;
  //   } catch (error) {
  //     throw error;
  //   }
  // }, []);

  // useLayoutEffect(() => {
  //   killServiceWorkers();
  // }, [killServiceWorkers]);

  // const clearCaches = useCallback(async () => {
  //   try {
  //     if (window.caches) {
  //       const keys = await caches.keys();
  //       for (const key of keys) {
  //         await caches.delete(key);
  //       }
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }, []);

  // useLayoutEffect(() => {
  //   if (!isAuthenticated) {
  //     clearCaches();
  //   }
  // }, [clearCaches, isAuthenticated]);

  return (
    <>
      <MessageBox
        clickText="Hier klicken um das Update durchzuführen"
        isDisplaying={isUpdateAvailable}
        text="Neues Update verfügbar"
        icon="LightBulbIcon"
        handleClick={update}
        handleClose={hideUpdateMsg}
      />
      <BrowserRouter>
        <ErrorBoundary>
          <UnauthenticatedTemplate>
            <Routes>
              <Route index element={<UnauthenticatedMainView />} />
              <Route path="/*" element={<UnauthenticatedMainView />} />
            </Routes>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <AppInsightsWrapper>
              <TrackContext.Provider value={trackGenericEvent}>
                <Routes>
                  <Route
                    path="/login"
                    element={
                      <div className="inline-flex w-full h-full bg-white justify-center items-center content-start gap-1.5">
                        <h3 className="text-lg text-gray-800">Login abgeschlossen</h3>
                        <span className="animate-pulse text-sm text-gray-600">Sie werden weitergeleitet...</span>
                        <DelayedNavigate to="/Reservierung" />
                      </div>
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      account && isAuthenticated && inProgress === InteractionStatus.None ? (
                        <AuthenticatedView device={device} trackEvent={trackClientApiEvent} />
                      ) : (
                        <span>
                          {account?.username ?? "Kein Account"} - {inProgress}
                        </span>
                      )
                    }
                  />
                  <Route index element={<DelayedNavigate to="/Reservierung" />} />
                </Routes>
              </TrackContext.Provider>
            </AppInsightsWrapper>
          </AuthenticatedTemplate>
        </ErrorBoundary>
      </BrowserRouter>
    </>
  );
};
