import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { useEventListener } from "../hooks/useEventlistener";
import { User } from "../controller/UserDataController";
import packageJson from "../../package.json";
import { TailwindBackgroundColors } from "../models/General";
import { Size } from "../types/Sizes";
import B2C from "../b2cpolicies";
import Tools from "../Tools";

export const POSSIBLE_MANDANTEN: ("UnitTesting" | "GezeitenLaAmarone")[] = ["UnitTesting", "GezeitenLaAmarone"];
const LAST_MANDANT_SAVELOCATION = "LAST_MANDANT_SAVELOCATION";
const lastMandant = localStorage.getItem(LAST_MANDANT_SAVELOCATION);

const isComparable = (value: any) => {
  if (
    typeof value === "undefined" ||
    typeof value === "bigint" ||
    typeof value === "boolean" ||
    typeof value === "number" ||
    typeof value === "string" ||
    typeof value === "symbol" ||
    (value && value.toISOString) ||
    value === null ||
    value === undefined
  ) {
    return true;
  } else return false;
};

const changeMobileBarColor = () => {
  const body = document.body;
  const transition = "200ms background-color ease-in-out";
  if (body && body.style && body.style.transition && body.style.transition !== transition) {
    body.style.transition = transition;
  }
  const metaThemeColor = document.querySelector("meta[name=theme-color]");
  const metaBackgroundColor = document.querySelector("meta[name=background-color]");
  const colorGrabber = document.getElementById("colorGrabber");
  if (colorGrabber) {
    const color = window.getComputedStyle(colorGrabber).backgroundColor;
    if (body.style.backgroundColor !== color) {
      body.style.backgroundColor = color;
    }
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", color);
    }
    if (metaBackgroundColor) {
      metaBackgroundColor.setAttribute("content", color);
    }
  }
};

const isObjectOfSameKind = (value: object, compare: object) =>
  Object.keys(value).every((k) => Object.keys(compare).some((sk) => k === sk));

const compareObject = (first: object, second: object) => {
  if (isObjectOfSameKind(first, second)) {
    const firstKeys: (keyof typeof first)[] = Object.keys(first) as (keyof typeof first)[];
    const isSame = firstKeys.reduce((is, b) => {
      if (is === true) {
        if (isComparable(first[b]) && isComparable(second[b])) {
          return first[b] === second[b];
        } else {
          return false;
        }
      }
      return is;
    }, true);
    return isSame;
  } else {
    return false;
  }
};

export type AppSettings = {
  serverVersion: number;
  clientVersion: number;
  serviceWorkerActive: boolean;
  serviceWorkerCachedPage: boolean;
  serviceWorkerUpdate: boolean;
  version: string;
  mandant: typeof POSSIBLE_MANDANTEN[0];
  title: string;
  date: Date;
  displayMode: "Web" | "Mobile" | "Tablet";
  isStandalone: boolean;
  user: User | null;
  shouldShowInstallPrompt: boolean;
  loadedDefaultSettings: boolean;
  dataColor: { [key: string]: TailwindBackgroundColors };
  showServerApiError: boolean;
  dateTableSize: "1" | "2" | "3" | "4" | "5" | "dynamic" | null;
  nachrichtenDaySpan: number;
  size: Size;
  isUsingVirtualKeyboard: boolean;
  isDepreactedUrl: boolean;
};

let defaultSettings: AppSettings = {
  serverVersion: 0,
  clientVersion: 0,
  serviceWorkerActive: false,
  serviceWorkerCachedPage: false,
  serviceWorkerUpdate: false,
  version: packageJson.version || "??",
  title: "MK Gastro Konzept\nTagesabrechnung",
  mandant:
    (lastMandant as typeof POSSIBLE_MANDANTEN[0] | undefined | null) ||
    (window.location.hostname === "localhost" || B2C.isDev ? POSSIBLE_MANDANTEN[0] : POSSIBLE_MANDANTEN[1]),
  date: new Date(),
  displayMode: "Web",
  isStandalone:
    window.matchMedia("(display-mode: standalone)").matches ||
    (window.navigator && (window.navigator as any).standalone) ||
    document.referrer.includes("android-app://"),
  user: null,
  shouldShowInstallPrompt: false,
  loadedDefaultSettings: false,
  dataColor: {},
  showServerApiError: false,
  dateTableSize: null,
  nachrichtenDaySpan: 3,
  size: "base",
  isUsingVirtualKeyboard: false,
  isDepreactedUrl: false,
};

const loadedSettingsKey = `Settings_${lastMandant ?? defaultSettings.mandant}`;
const loadedSettingsString = localStorage.getItem(loadedSettingsKey);
const loadedSettings = loadedSettingsString ? JSON.parse(loadedSettingsString) : defaultSettings;

let Settings: AppSettings = {
  ...defaultSettings,
  ...loadedSettings,
  date: new Date(),
};

const _getSettings = () => {
  let temp = { ...Settings };
  const keysTemp: (keyof AppSettings)[] = Object.keys(temp) as (keyof AppSettings)[];
  const defaultKeys: (keyof AppSettings)[] = Object.keys(defaultSettings) as (keyof AppSettings)[];

  const missingKeys: (keyof AppSettings)[] = defaultKeys.filter(
    (c) => !keysTemp.some((t) => c === t)
  ) as (keyof AppSettings)[];

  if (missingKeys.length) {
    missingKeys.forEach((key) => {
      if (defaultSettings[key] !== undefined) {
        temp = { ...temp, [key]: defaultSettings[key] };
      }
    });
  }
  return temp;
};

const get = <T extends keyof AppSettings>(key: T) => {
  return _getSettings()[key];
};

const set = <T extends keyof AppSettings>(key: T, value: typeof Settings[T]): typeof Settings[T] => {
  console.log({ key, value, Settings });
  Settings = { ...Settings, [key]: value };
  localStorage.setItem(`Settings_${key === "mandant" ? value : Settings.mandant}`, JSON.stringify(Settings));
  const event = new CustomEvent<string>(Events.SETTINGS_UPDATED, {
    detail: key.toUpperCase(),
  });
  window.dispatchEvent(event);
  return Settings[key];
};

const Events = {
  SETTINGS_ALIVE: "SETTINGS_ALIVE",
  SETTINGS_UPDATED: "SETTINGS_UPDATED",
};

export const useSettings = () => {
  const [settings, setSettings] = useState<AppSettings>(Settings);

  const settingsKeys: (keyof typeof settings)[] = useMemo(
    () => Object.keys(settings).filter((k) => k !== "user") as (keyof typeof settings)[],
    [settings]
  );
  const handleSettingsChange = useCallback(() => {
    const change = !settingsKeys.every((v) => {
      if (isComparable(settings[v])) {
        return settings[v] === get(v);
      } else {
        return true;
      }
    });
    if (change) {
      setSettings(_getSettings());
    }
  }, [settings, settingsKeys]);

  useEffect(() => {
    handleSettingsChange();
  });

  const isoDate = useMemo(() => Tools.dateToIsoLike(settings.date), [settings.date]);

  useEventListener([Events.SETTINGS_UPDATED, Events.SETTINGS_ALIVE], handleSettingsChange);

  const _handleMandantChange = useCallback((ev: CustomEvent<keyof AppSettings>) => {
    localStorage.setItem(LAST_MANDANT_SAVELOCATION, get("mandant"));
    if (ev.detail.toUpperCase() === "MANDANT" && get("user") !== null) {
      const defSettings = localStorage.getItem(`Settings_${get("mandant")}`);
      if (defSettings) {
        const parsedDef: AppSettings = JSON.parse(defSettings);
        const {
          date,
          version,
          serviceWorkerActive,
          serviceWorkerCachedPage,
          serviceWorkerUpdate,
          showServerApiError,
          ...strippedParsedDef
        } = parsedDef;
        defaultSettings = { ...defaultSettings, ...strippedParsedDef };
        Settings = { ...Settings, ...defaultSettings };
        set("loadedDefaultSettings", true);
      }
    }
  }, []);

  useEventListener(Events.SETTINGS_UPDATED, _handleMandantChange);

  return { ...settings, isoDate, set, Events, changeMobileBarColor };
};

const SettingsProvider = { set, get, Events, POSSIBLE_MANDANTEN };

export default SettingsProvider;

// Extrahieren?
