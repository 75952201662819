import { AccountInfo } from "@azure/msal-browser";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { ChangeEvent } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Package from "../package.json";
import { reactPlugin } from "./AppInsights";
import CachedDataProvider from "./clientApi/CachedDataProvider";
import ClientApi, { Events, SAVELOCATIONS } from "./clientApi/ClientApi";
import { Nachricht } from "./clientApi/models/Nachricht";
import SettingsProvider from "./clientApi/SettingsProvider";
import { AppLink } from "./components/AppLink";
import { AppNavBar } from "./components/AppNavBar";
import { AppRouteSwitch } from "./components/AppRouteSwitch";
import DelayedNavigate from "./components/DelayedNavigate";
import ErrorBoundary from "./components/ErrorBoundary";
import { InstallPrompt } from "./components/InstallPrompt";
import { MessageBox } from "./components/MessageBox";
import ModalHandler from "./components/modal/Modal";
import { Overview } from "./components/Overview";
import { PurgeExceptions } from "./components/PurgeExceptions";
import {
  getCausesForDate,
  getCausesForDateSpan,
  saveCauseInStorage,
  saveCausesInStorageForDateSpan,
} from "./controller/CauseController";
import { getAllEventsForDate, getEventsForDate } from "./controller/EventController";
import {
  createBankett,
  getAllReservationsForDate,
  getEmail,
  getReservationSummaryForMonth,
  getSummariesForMonth,
  saveReservationsInStorage,
  sendEmail,
} from "./controller/ReservierungDataController";
import { getAllTables, getTablesForRestaurant } from "./controller/TableController";
import { loadTendant, Tenant } from "./controller/TendantDataController";
import { Device } from "./hooks/useDevice";
import { addProtocol } from "./hooks/useProtocol";
import * as logo from "./img/mk-gastro-800x800-png.png";
import Information from "./Informationen/types/Information";
import { Cause } from "./models/Cause";
import { Dish } from "./models/Dish";
import { Email } from "./models/Email";
import { GastroEvent } from "./models/GastroEvent";
import {
  AppColors,
  DisplayMode,
  getSettingsFromStorage,
  getSettingsLocation,
  isAppMobile,
  isIOS,
  saveSettingsInStorage,
} from "./models/General";
import { Reservation } from "./models/Reservation";
import { SummaryTable } from "./models/Summary";
import { Supplier } from "./models/Supplier";
import { Table } from "./models/Table";
import { WebSocketCall } from "./models/WebSocketCall";
import { Size } from "./types/Sizes";
import AbspracheMainView from "./views/Absprache/main/AbspracheMainView";
import { clientApi } from "./views/Authenticated/AuthenticatedView";
import DebugConnectionView from "./views/DebugConnection/DebugConnectionView";
import { ErrorView } from "./views/Error";
import { ExportMainView } from "./views/Export/main/ExportMainView";
import ReservierungWeb from "./views/ReservierungenWeb";
import ReservierungMobile from "./views/ReservierungMobile";
import ReservierungTablet from "./views/ReservierungTablet";
import StorageRoutes from "./views/storage/constants/StorageRoutes";
import StorageMainView from "./views/storage/main/StorageMainView";
import { Test } from "./views/Test";
import { ViewLoader } from "./views/ViewLoader/viewLoader";

const appVersion = Package.version;

const defaultTransition = { transition: "all 300ms" };

const _drawerVisibleStyle = {
  transitionDuration: "200ms",
  transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
  right: "0%",
  transform: `translate(0px, 0px)`,
  backgroundColor: "rgba(255,255,255,0.85)",
};

const _drawerInvisibleStyle = {
  transitionDuration: "200ms",
  transitionTimingFunction: "cubic-bezier(0.42, 0, 0.58, 1)",
  right: "0%",
  transform: `translate(101vw, 0px)`,
  backgroundColor: "rgba(255,255,255,0.85)",
};

export interface AppState {
  appTenant: Tenant | undefined;
  appRestaurantId: string;
  appColors: AppColors;
  appSuppliers: Supplier[];
  appDishes: Dish[];
  appReservations: Reservation[];
  appTables: Table[];
  appCauses: Cause[];
  appNachrichten: Nachricht.Client<any>[];
  appEvents: GastroEvent[];
  appHeadTitle?: string;
  appWebSocket: WebSocket | undefined;
  appName: string;
  appShouldCreateReservation: (Partial<Reservation> & { cause?: Cause }) | null;
  appShouldShowReservationDetail: Reservation | null;
  isLoadingApp: boolean;
  hasErrorOccured: boolean;
  appError: string | undefined;
  // appBackgroundImage: typeof Background1 | typeof Background2 | typeof Background3 | typeof Background4;
  appNotificationBarVisible: boolean;
  appRedirect: boolean;
  appReservationDate: Date;
  appOverviewVisible: boolean;
  appDrawerIsVisible: boolean;
  appDisplayMode: DisplayMode;
  appColSize: number | undefined;
  appIsStandalone: boolean;
  appIsMobile: boolean;
  appIsIOS: boolean;
  appIsLoading: {
    Reservations: boolean;
    Tables: boolean;
    Causes: boolean;
    Events: boolean;
  };
  appIsPulling: {
    Reservations: boolean;
    Tables: boolean;
    Causes: boolean;
    Events: boolean;
  };
  appSummary: SummaryTable | null;
  appWaitingServiceWorker: ServiceWorker | null;
  appIsUpdateAvailable: boolean;
  appIsShowingUpdatePrompt: boolean;
  appCurrentServiceWorker: ServiceWorker | null;
  appCurrentServiceWorkerRegristration: ServiceWorkerRegistration | null;
  appIsCached: boolean;
  appShouldShowInstallPrompt: boolean;
  appShouldShowNotificationPrompt: boolean;
  appNotificationToShow: { title: string; options?: NotificationOptions } | undefined;
  appCallCause: Cause | undefined;
  appIsShowingCallBubble: boolean;
  appSize: Size;
}

// const images = [Background1, Background2, Background3, Background4];

// Inputs auf anderen Ansichten springen umher?

interface AppProps {
  account: AccountInfo | null;
  device: Device;
  navigate: ReturnType<typeof useNavigate>;
}

export interface AppFunctions {
  setAppState: <K extends keyof AppState>(appProp: K, appValue: AppState[K]) => Promise<void>;
  mapWebSocketCallMessage: (text: string) => WebSocketCall;
  setNewReservationPrimer: (props: Partial<Reservation> & { cause?: Cause }) => Promise<void>;
  releaseNewReservationPrimer: () => Promise<void>;
  releaseReservationDetailPrimer: () => Promise<void>;
  appLoadReservations: (date?: Date) => Promise<void>;
  appLoadTables: () => Promise<void>;
  appLoadEvents: (date?: Date) => Promise<void>;
  appLoadCauses: () => Promise<void>;
  appLoadSummary: (year: number, month: number) => Promise<void>;
  appSetOverview: (bool: boolean) => Promise<void>;
  appGetSummary: ({ year, month }: { year: number; month: number }, cb: any) => void;
  appSetDate: (date: string) => Promise<void>;
  appShowNotification: (props: { title: string; options?: NotificationOptions }) => Promise<void>;
  appInitialiseWebsocket: (timeout?: number, closeCurrent?: boolean) => Promise<void>;
  getEmail: (res: Reservation) => Promise<Email>;
  sendEmail: (email: Email, resId: Reservation["id"]) => Promise<void>;
  createBankett: (res: Reservation) => Promise<void>;
}

type returnedData = {
  tendant: Tenant;
  suppliers: Supplier[];
  dishes: Dish[];
  reservations: Reservation[];
  tables: Table[];
  causes: any[];
  events: GastroEvent[];
  appNachrichten: Nachricht.Client<any>[];
};

class App extends React.PureComponent<AppProps, AppState> {
  AppFunctions: AppFunctions;

  constructor(props: AppProps) {
    super(props);
    const savedSettings = getSettingsFromStorage(
      getSettingsLocation(window.location.hostname === "localhost" ? "UnitTesting" : "GezeitenLaAmarone")
    );
    let color: AppColors = {
      backgroundcolor: "blue",
      textcoloroncolor: "white",
      textdefaultcolor: "gray-900",
    };
    let display = undefined;
    let colSize = undefined;

    if (savedSettings) {
      color = savedSettings.AppColor;
      display = savedSettings.appDisplayMode ?? undefined;
      colSize = savedSettings.appColSize ?? colSize;
    }

    const now = new Date();

    this.state = {
      appTenant: undefined,
      //appRestaurantId: "UnitTesting",
      appRestaurantId:
        SettingsProvider.get("mandant") ||
        (window.location.hostname === "localhost" ? "UnitTesting" : "GezeitenLaAmarone"),
      appColors: color,
      appSuppliers: [],
      appDishes: [],
      appReservations: [],
      appTables: [],
      appCauses: [],
      appNachrichten: [],
      appEvents: [],
      appHeadTitle: undefined,
      appWebSocket: undefined,
      appName: "MKGastro",
      appShouldCreateReservation: null,
      appShouldShowReservationDetail: null,
      isLoadingApp: true,
      hasErrorOccured: false,
      appError: undefined,
      // appBackgroundImage: Background2,
      appNotificationBarVisible: false,
      appRedirect: false,
      appReservationDate: now,
      appOverviewVisible: false,
      appDrawerIsVisible: false,
      appDisplayMode: SettingsProvider.get("displayMode"),
      appColSize: colSize,
      appIsStandalone:
        window.matchMedia("(display-mode: standalone)").matches ||
        (window.navigator && (window.navigator as any).standalone) ||
        document.referrer.includes("android-app://"),
      appIsMobile: isAppMobile(),
      appIsIOS: isIOS(),
      appIsLoading: {
        Reservations: true,
        Tables: true,
        Causes: true,
        Events: true,
      },
      appIsPulling: {
        Reservations: true,
        Tables: true,
        Causes: true,
        Events: true,
      },
      appSummary: null,
      appWaitingServiceWorker: null,
      appIsUpdateAvailable: false,
      appIsShowingUpdatePrompt: false,
      appCurrentServiceWorker: null,
      appCurrentServiceWorkerRegristration: null,
      appIsCached: false,
      appShouldShowInstallPrompt: savedSettings ? savedSettings.showInstallPrompt : true,
      appShouldShowNotificationPrompt: savedSettings ? savedSettings.showNotificationPrompt : true,
      appNotificationToShow: undefined,
      appCallCause: undefined,
      appIsShowingCallBubble: false,
      appSize: savedSettings ? savedSettings.appSize ?? "base" : "base",
    };
    this.AppFunctions = {
      setAppState: this._setAppState,
      mapWebSocketCallMessage: this._mapWebSocketCallMessage,
      releaseNewReservationPrimer: this._releaseNewReservationPrimer,
      releaseReservationDetailPrimer: this._releaseReservationDetailPrimer,
      setNewReservationPrimer: this._setNewReservationPrimer,
      appLoadCauses: this._loadAppCauses,
      appLoadEvents: this._loadAppEvents,
      appLoadReservations: this._loadAppReservations,
      appLoadTables: this._loadAppTables,
      appLoadSummary: this._loadAppSummary,
      appSetOverview: this._setAppOverview,
      appGetSummary: this._appGetSummary,
      appSetDate: this._setAppDate,
      appShowNotification: this._appShowNotification,
      appInitialiseWebsocket: this._initialiseWebSocket,
      getEmail: this._getEmail,
      sendEmail: this._sendEmail,
      createBankett: this._createBankett,
    };
  }

  componentDidMount() {
    try {
      console.log("App Mounted");
      this._handleInitialise().then(() => {
        window.addEventListener(Events.EINGEHENDER_ANRUF, this._handleCause as any);
        window.addEventListener(Events.RESERVIERUNGEN_UPDATED, () => {
          this.setState(
            (cs) => ({
              appIsLoading: { ...cs.appIsLoading, Reservations: true },
            }),
            () => {
              CachedDataProvider.get<string, Reservation[]>(
                SAVELOCATIONS.reservierungen,
                this.state.appReservationDate
              ).then((cachedData) => {
                return this.setState((cs) => ({
                  appIsLoading: { ...cs.appIsLoading, Reservations: false },
                  appReservations: cachedData ?? cs.appReservations,
                }));
              });
            }
          );
        });
        // window.addEventListener(ClientApi.Events.NACHRICHTEN_UPDATED, () => {
        //   this._loadAppNachrichten(false, false);
        // });
      });

      // window.addEventListener(SettingsProvider.Events.SETTINGS_UPDATED, (ev) => {
      //   const updateAvailable = SettingsProvider.get("serviceWorkerUpdate");
      //   const cached = SettingsProvider.get("serviceWorkerCachedPage");
      //   this.setState({
      //     appIsUpdateAvailable: updateAvailable,
      //     appIsCached: cached,
      //     appWaitingServiceWorker: waitingSW.SW || null,
      //   });
      // });
    } catch (error) {
      console.error("cdm", error);
      throw error;
    }
  }

  _handleCause = (ev: CustomEvent<Cause>) => {
    try {
      if (ev && "detail" in ev) {
        const { detail } = ev;
        if (detail) {
          this.setState(
            {
              appCallCause: detail,
              appIsShowingCallBubble: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  appCallCause: undefined,
                  appIsShowingCallBubble: false,
                });
              }, 15000);
            }
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate(prevprops: AppProps, prevState: AppState) {
    if (prevprops.device !== this.props.device) {
      this.setState({
        appDisplayMode:
          this.props.device === Device.Desktop ? "Web" : this.props.device === Device.Tablet ? "Tablet" : "Mobile",
      });
    }
    const savedSettings = getSettingsFromStorage(getSettingsLocation(this.state.appRestaurantId));
    if (prevState.appReservations !== this.state.appReservations) {
      saveReservationsInStorage({
        restaurantId: this.state.appRestaurantId,
        date: this.state.appReservationDate.toISOString().split("T")[0],
        reservations: this.state.appReservations,
      });
    }
    if (prevState.appDisplayMode !== this.state.appDisplayMode) {
      this._handleAppDisplay();
    }
    if (prevState.appColSize !== this.state.appColSize) {
      this._handleAppColSize();
    }
    if (prevState.appRestaurantId !== this.state.appRestaurantId) {
      if (
        savedSettings &&
        savedSettings.AppColor &&
        savedSettings.AppColor.backgroundcolor !== this.state.appColors.backgroundcolor
      ) {
        this.setState({
          appColors: savedSettings.AppColor,
        });
      }
      this._handleInitialise()
      // .then(() => {
      //   this._initialiseWebSocket();
      // });
    }
    if (prevState.appReservationDate !== this.state.appReservationDate) {
      this._loadAppReservations();
      this._loadAppTables();
      this._loadAppEvents();
      if (
        prevState.appReservationDate.getMonth() !== this.state.appReservationDate.getMonth() ||
        prevState.appReservationDate.getFullYear() !== this.state.appReservationDate.getFullYear()
      ) {
        const month = this.state.appReservationDate.getMonth() + 1;
        const year = this.state.appReservationDate.getFullYear();
        this._loadAppSummary(year, month);
      }
    }
  }

  _initialise = (dataToLoad: (() => Promise<any>)[]) =>
    new Promise<returnedData>(async (resolve, reject) => {
      try {
        const promiseArray = dataToLoad.map(
          (getter) =>
            new Promise<any>(async (resolve, reject) => {
              try {
                const value = await getter();
                return resolve(value);
              } catch (error) {
                console.error(error);
                return resolve({});
              }
            })
        );
        const returnedResponses = await Promise.allSettled(promiseArray);
        const returnedData: any[] = (
          returnedResponses.filter((c) => c.status === "fulfilled") as PromiseFulfilledResult<any>[]
        ).map((c) => c.value);
        const dataObject: returnedData = returnedData.reduce((previousData, specificData) => {
          return { ...previousData, ...specificData };
        }, {});
        return resolve(dataObject as any);
      } catch (error) {
        reject(error);
        throw error;
      }
    });

  _handleInitialise = () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const dateToShow = new Date();
        const dateStringToday = dateToShow.toISOString().slice(0, 10);
        let dateThreeDaysAgo = new Date();
        dateThreeDaysAgo = new Date(dateThreeDaysAgo.setDate(dateThreeDaysAgo.getDate() - 3));
        const dateStringThreeDaysAgo = dateThreeDaysAgo.toISOString().slice(0, 10);
        let loadsNew = false;
        let dataToLoad: (() => Promise<any>)[] = [() => loadTendant()];
        if ("UnitTesting_settings" in localStorage || "GezeitenLaAmarone_settings" in localStorage) {
          this._loadAppCauses();
          this._loadAppEvents();
          this._loadAppTables();
          this._loadAppReservations();
          this._loadAppSummary(dateToShow.getFullYear(), dateToShow.getMonth() + 1);
        } else {
          loadsNew = true;
          dataToLoad = [
            ...dataToLoad,
            () => getAllReservationsForDate(this.state.appRestaurantId, dateStringToday),
            () => getAllTables(this.state.appRestaurantId),
            () => getCausesForDateSpan(this.state.appRestaurantId, dateStringThreeDaysAgo, dateStringToday),
            () => getAllEventsForDate(this.state.appRestaurantId, dateStringToday),
            () =>
              getReservationSummaryForMonth(
                this.state.appRestaurantId,
                dateToShow.getFullYear(),
                dateToShow.getMonth() + 1
              ),
          ];
        }

        const returnedData = await this._initialise([
          ...dataToLoad,
          // () => getAllSuppliers(),
          // () => getAllDishes(),
        ]);
        this.setState(
          (cs) => ({
            isLoadingApp: false,
            appTenant: returnedData.tendant,
            appSuppliers: returnedData.suppliers,
            appDishes: returnedData.dishes,
            appReservations: returnedData.reservations || cs.appReservations,
            appTables: returnedData.tables || cs.appTables,
            appCauses: returnedData.causes || cs.appCauses,
            appEvents: returnedData.events || cs.appEvents,
            appHeadTitle: undefined,
            appIsLoading: !loadsNew
              ? cs.appIsLoading
              : {
                  Reservations: false,
                  Tables: false,
                  Causes: false,
                  Events: false,
                },
            appIsPulling: !loadsNew
              ? cs.appIsPulling
              : {
                  Reservations: false,
                  Tables: false,
                  Causes: false,
                  Events: false,
                },
            appOverviewVisible: true,
          }),
          () => {
            saveSettingsInStorage(getSettingsLocation(this.state.appRestaurantId), {
              AppColor: this.state.appColors,
            });
            if (returnedData.causes) {
              saveCausesInStorageForDateSpan(
                returnedData.causes,
                dateStringThreeDaysAgo,
                dateStringToday,
                this.state.appRestaurantId
              );
            }
            return resolve();
          }
        );
      } catch (error) {
        throw reject(error);
      }
    });
  };

  _initialiseWebSocket = async (pTimeout?: number, closeCurrent?: boolean) => {
    try {
      const timeout = pTimeout || 5000;
      if (closeCurrent && this.state.appWebSocket) {
        this.state.appWebSocket.close(1000);
      }
      const url = window.location.host.includes("localhost")
        ? `wss://localhost:44315/websocket/calls`
        : window.location.host.includes("192.168.")
        ? "ws://192.168.178.28:45456"
        : `wss://${window.location.host}/websocket/calls`;

      addProtocol({ desc: "Websocket connecting....", data: url, type: "API" });
      const NEW_WEBSOCKET = new WebSocket(url);
      NEW_WEBSOCKET.onopen = () => {
        addProtocol({ desc: "Websocket connected!", data: url, type: "API" });
      };
      NEW_WEBSOCKET.addEventListener("close", (e) => {
        if (e.code === 1000) {
          return;
        } else {
          addProtocol({ desc: "Websocket disconnected", data: url, type: "API" });
          NEW_WEBSOCKET.close();
          if (this.state.appWebSocket) {
            setTimeout(() => this._initialiseWebSocket(timeout + 1000), timeout);
          }
        }
      });
      NEW_WEBSOCKET.addEventListener("error", (ev) => {
        try {
          const val = { ...ev };
          addProtocol({ desc: `Websocket Error - ${url}`, data: JSON.stringify(val, null, 2), type: "API" });
          return NEW_WEBSOCKET.close();
        } catch (error) {
          console.error(error);
        }
      });
      NEW_WEBSOCKET.addEventListener("message", this._handleWebSocketMessage);
      return this.setState({
        appWebSocket: NEW_WEBSOCKET,
      });
    } catch (error) {
      throw error;
    }
  };

  _handleWebSocketMessage = async (msg: MessageEvent) => {
    try {
      const parsed: WebSocketCall = JSON.parse(msg.data);
      if (parsed && !(parsed as any).isDebug) {
        const tempCause: Cause = {
          id: parsed.causeId || parsed.callId,
          from: parsed.from,
          timestamp: new Date().toISOString(),
          body: null,
          modifiedBy: "--",
          note: "",
          type: "Call",
          subject: "",
          state: 0,
          reservationId: "",
        };
        saveCauseInStorage(tempCause, this.state.appRestaurantId);
        const resp = await this._loadAppCauses();
        return resp;
      } else return undefined;
    } catch (error) {
      throw error;
    }
  };

  _setAppState = <K extends keyof AppState>(appProp: K, appValue: AppState[K]) =>
    new Promise<void>((resolve, reject) => {
      try {
        this.setState<never>(
          {
            [appProp]: appValue,
          },
          resolve
        );
      } catch (error) {
        throw reject({ caller: "setAppState", error: error });
      }
    });

  _setAppRestaurantId = (restaurantId: "UnitTesting" | "GezeitenLaAmarone") =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        {
          appRestaurantId: restaurantId,
          isLoadingApp: true,
        },
        () => {
          try {
            this._handleInitialise().then(resolve).catch(reject);
          } catch (error) {
            throw reject(error);
          }
        }
      );
    });

  _releaseNewReservationPrimer = () =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        {
          appShouldCreateReservation: null,
        },
        resolve
      );
    });

  _releaseReservationDetailPrimer = () =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        {
          appShouldShowReservationDetail: null,
        },
        resolve
      );
    });

  _setNewReservationPrimer = (props: Partial<Reservation> & { cause?: Cause }) =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        (cs) => ({
          appShouldCreateReservation: props,
          appReservationDate: props.dateOfArrival ? new Date(props.dateOfArrival) : cs.appReservationDate,
        }),
        resolve
      );
    });

  _mapWebSocketCallMessage = (text: string) =>
    decodeURI(text)
      .replace(/\[|\]/g, "")
      .split("&")
      .reduce((prev, curr) => {
        const [param, value] = curr.split("=");
        return { ...prev, [param]: value };
      }, {}) as WebSocketCall;

  // _getSummary = async (year: number, month: number) =>
  //   new Promise<SummaryTable | undefined>((resolve, reject) => {
  //     getSummariesForMonth(
  //       { restaurantId: this.state.appRestaurantId, year: year, month: month },
  //       (summary: SummaryTable | undefined, year: number, month: number, isFresh: boolean) => {
  //         resolve(summary);
  //       }
  //     );
  //   });

  _loadAppReservations = (date: Date = this.state.appReservationDate, onlyFresh: boolean = false) =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        (cs) => ({
          appIsLoading: { ...cs.appIsLoading, Reservations: true },
          appIsPulling: { ...cs.appIsPulling, Reservations: true },
        }),
        async () => {
          try {
            let stale = !onlyFresh ? await clientApi.Queries.getReservierungenByDate(date, false) : null;
            this.setState((cs) =>
              date === cs.appReservationDate
                ? {
                    appReservations: stale ?? cs.appReservations,
                    appIsLoading: { ...cs.appIsLoading, Reservations: false },
                    appIsPulling: { ...cs.appIsLoading, Reservations: true },
                  }
                : null
            );
            const fresh = await clientApi.Queries.getReservierungenByDate(date, true);
            this.setState((cs) =>
              date === cs.appReservationDate
                ? {
                    appReservations: fresh ?? cs.appReservations,
                    appIsLoading: { ...cs.appIsLoading, Reservations: false },
                    appIsPulling: { ...cs.appIsLoading, Reservations: false },
                  }
                : null
            );
            resolve();
          } catch (error) {
            this.setState((cs) => ({
              appIsLoading: { ...cs.appIsLoading, Reservations: false },
              appIsPulling: { ...cs.appIsLoading, Reservations: false },
            }));
            reject(error);
          }

          // getReservationsForDate(
          //   {
          //     restaurantId: this.state.appRestaurantId,
          //     date: date.toISOString().slice(0, 10),
          //   },
          //   async (reservations: Reservation[], date: string, isFresh: boolean) => {
          //     try {
          //       if (date === this.state.appReservationDate.toISOString().slice(0, 10)) {
          //         if (onlyFresh) {
          //           if (isFresh) {
          //             console.log(reservations);
          //             this.setState(
          //               (cs) => ({
          //                 appReservations: [...reservations],
          //                 appIsLoading: { ...cs.appIsLoading, Reservations: false },
          //                 appIsPulling: { ...cs.appIsPulling, Reservations: !isFresh },
          //               }),
          //               resolve
          //             );
          //           }
          //         } else {
          //           this.setState(
          //             (cs) => ({
          //               appReservations: [...reservations],
          //               appIsLoading: { ...cs.appIsLoading, Reservations: false },
          //               appIsPulling: { ...cs.appIsPulling, Reservations: !isFresh },
          //             }),
          //             onlyFresh ? undefined : resolve
          //           );
          //         }
          //       }
          //     } catch (error) {
          //       reject();
          //       throw error;
          //     }
          //   }
          // );
        }
      );
    });

  _loadAppTables = () =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        (cs) => ({
          appIsLoading: { ...cs.appIsLoading, Tables: true },
          appIsPulling: { ...cs.appIsPulling, Tables: true },
        }),
        () => {
          getTablesForRestaurant(
            { restaurantId: this.state.appRestaurantId },
            async (tables: Table[], isFresh: boolean) => {
              try {
                this.setState(
                  (cs) => ({
                    appTables: tables,
                    appIsLoading: { ...cs.appIsLoading, Tables: false },
                    appIsPulling: { ...cs.appIsPulling, Tables: !isFresh },
                  }),
                  resolve
                );
              } catch (error) {
                reject();
                throw error;
              }
            }
          );
        }
      );
    });

  _loadAppEvents = (date: Date = this.state.appReservationDate) =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        (cs) => ({
          appIsLoading: { ...cs.appIsLoading, Events: true },
          appIsPulling: { ...cs.appIsPulling, Events: true },
        }),
        () => {
          getEventsForDate(
            {
              restaurantId: this.state.appRestaurantId,
              date: date.toISOString().slice(0, 10),
            },
            (events: GastroEvent[], date: string, isFresh: boolean) => {
              try {
                if (date === this.state.appReservationDate.toISOString().slice(0, 10)) {
                  this.setState(
                    (cs) => ({
                      appEvents: events,
                      appIsLoading: { ...cs.appIsLoading, Events: false },
                      appIsPulling: { ...cs.appIsPulling, Events: !isFresh },
                    }),
                    resolve
                  );
                } else return;
              } catch (error) {
                reject();
                throw error;
              }
            }
          );
        }
      );
    });

  _loadAppCauses = () =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        (cs) => ({
          appIsLoading: { ...cs.appIsLoading, Causes: true },
          appIsPulling: { ...cs.appIsPulling, Causes: true },
        }),
        () => {
          const dateToShow = new Date();
          const dateStringToday = dateToShow.toISOString().split("T")[0];
          let dateThreeDaysAgo = new Date();
          dateThreeDaysAgo = new Date(dateThreeDaysAgo.setDate(dateThreeDaysAgo.getDate() - 3));
          const dateStringThreeDaysAgo = dateThreeDaysAgo.toISOString().split("T")[0];
          getCausesForDate(
            { restaurantId: this.state.appRestaurantId, dateStart: dateStringThreeDaysAgo, dateEnd: dateStringToday },
            (causes: Cause[], date: string, isFresh: boolean) => {
              try {
                if (date === dateStringThreeDaysAgo) {
                  this.setState(
                    (cs) => ({
                      appCauses: causes,
                      appIsLoading: { ...cs.appIsLoading, Causes: false },
                      appIsPulling: { ...cs.appIsPulling, Causes: !isFresh },
                    }),
                    resolve
                  );
                } else return;
              } catch (error) {
                reject();
                throw error;
              }
            }
          );
        }
      );
    });

  _loadAppSummary = (year: number, month: number) =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        (cs) => ({
          appIsLoading: { ...cs.appIsLoading, Summary: true },
          appIsPulling: { ...cs.appIsPulling, Summary: true },
        }),
        () => {
          getSummariesForMonth(
            { restaurantId: this.state.appRestaurantId, year: year, month: month },
            async (summary: SummaryTable | undefined, yearcb: number, monthcb: number, isFresh: boolean) => {
              try {
                if (yearcb === year && monthcb === month) {
                  this.setState(
                    (cs) => ({
                      appSummary: summary || null,
                      appIsLoading: { ...cs.appIsLoading, Summary: false },
                      appIsPulling: { ...cs.appIsPulling, Summary: !isFresh },
                    }),
                    resolve
                  );
                } else return;
              } catch (error) {
                reject();
                throw error;
              }
            }
          );
        }
      );
    });

  _appGetSummary = ({ year, month }: { year: number; month: number }, cb: any) =>
    getSummariesForMonth({ restaurantId: this.state.appRestaurantId, year: year, month: month }, cb);

  _setAppOverview = async (bool: boolean) => {
    try {
      await this._setAppState("appDrawerIsVisible", bool);
      return;
    } catch (error) {
      throw error;
    }
  };

  _setAppDate = (date: string) =>
    new Promise<void>((resolve, reject) => {
      const oldDate = this.state.appReservationDate.toISOString();
      try {
        this.setState(
          {
            appReservationDate: new Date(date),
          },
          async () => {
            // const isodate = this.state.appReservationDate.toISOString();
            // const year = isodate.slice(0, 4);
            // const month = isodate.slice(5, 7);
            // this._overViewGetSummary({ year: +year, month: +month }, () => {});
            return resolve();
          }
        );
        return;
      } catch (error) {
        this.setState(
          {
            appReservationDate: new Date(oldDate),
          },
          reject
        );
        throw error;
      }
    });

  _handleCreatedSummary = (sum: SummaryTable | null) =>
    new Promise<void>((resolve, reject) => {
      this.setState(
        {
          appSummary: sum,
        },
        resolve
      );
    });

  _overViewGetSummary = ({ year, month }: { year: number; month: number }, cb: any) =>
    getSummariesForMonth({ restaurantId: this.state.appRestaurantId, year: year, month: month }, cb);

  _handleOverviewDateSelect = async (date: string) => {
    try {
      await this._setAppDate(date);
      if (this.props.navigate) this.props.navigate("/Reservierung");
      await this._setAppOverview(false);
    } catch (error) {
      throw error;
    }
  };

  _getOverviewDate = (date: Date) =>
    date
      .toLocaleString("de-de", {
        weekday: "long",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split(",")[1];

  _getOverviewDay = (date: Date) =>
    date
      .toLocaleString("de-de", {
        weekday: "long",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split(",")[0];

  _getSelectedOverviewDate = (date: Date) => date.toISOString().slice(0, 10);

  _isActive = (match: any, loc: any) =>
    loc.pathname === "/" ||
    loc.pathname.includes("/3") ||
    (this.state.appDisplayMode !== "Tablet" && loc.pathname.toLowerCase().includes("/Reservierung/".toLowerCase()));

  _handleCloseOverview = async () => {
    this._setAppOverview(false);
  };

  _handleAppColSize = () => {
    saveSettingsInStorage(getSettingsLocation(this.state.appRestaurantId), {
      appColSize: this.state.appColSize,
    });
  };

  _handleAppDisplay = () => {
    saveSettingsInStorage(getSettingsLocation(this.state.appRestaurantId), {
      appDisplayMode: this.state.appDisplayMode,
    });
  };

  _handleNotificationNeverShow = () => {
    saveSettingsInStorage(getSettingsLocation(this.state.appRestaurantId), {
      showNotificationPrompt: false,
    });
  };

  _handleInstallNeverShow = () => {
    saveSettingsInStorage(getSettingsLocation(this.state.appRestaurantId), {
      showInstallPrompt: false,
    });
  };

  _handleAfterInstall = (choice: boolean) => {
    saveSettingsInStorage(getSettingsLocation(this.state.appRestaurantId), {
      showInstallPrompt: !choice,
    });
  };

  _handleCloseUpdatePrompt = () => {
    this.setState({
      appIsShowingUpdatePrompt: false,
    });
  };

  _showBadge = (amount: number) => {
    if (navigator && "serviceWorker" in navigator && navigator.serviceWorker) {
      const payload = { type: "BADGE", amount };
      const { serviceWorker } = navigator;
      serviceWorker.controller?.postMessage(payload);
    }
  };

  _renderNotificationNumber = () => {
    const nachrichten: (Nachricht.Client<any> | Cause)[] = this.state.appCauses ?? [];
    const tempDate = new Date();
    tempDate.setDate(tempDate.getDate() - 3);
    tempDate.setHours(0);
    tempDate.setMinutes(1);
    tempDate.setSeconds(1, 1);
    const newCauses = nachrichten.filter(
      (c) => (typeof c.timestamp === "string" ? new Date(c.timestamp) : c.timestamp) >= tempDate && c.state <= 0
    ).length;
    this._showBadge(newCauses);
    if (newCauses) {
      return (
        <div
          className={`absolute flex justify-center items-center top-0 bg-red-500 text-white border-2 border-primary-500 ${
            newCauses >= 10 ? "w-7" : "w-5"
          } h-5 text-xs rounded-full z-20 leading-none font-semibold tracking-normal`}
          style={{
            right: newCauses >= 10 ? "-1rem" : "-0.5rem",
            top: "-0.25rem",
          }}
        >
          {newCauses}
        </div>
      );
    } else return null;
  };

  _appShowNotification = async (props: { title: string; options?: NotificationOptions }) => {
    try {
      await this._setAppState("appNotificationToShow", props);
      await this._setAppState("appNotificationToShow", undefined);
      return;
    } catch (error) {
      throw error;
    }
  };

  _getEmail = async (res: Reservation) => {
    let email = getEmail(this.state.appRestaurantId, res);
    return email;
  };

  _sendEmail = async (email: Email, resId: Reservation["id"]) => {
    sendEmail(this.state.appRestaurantId, email, resId);
  };

  _createBankett = async (res: Reservation) => {
    return await createBankett(this.state.appRestaurantId, res);
  };

  _handleMenuChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    if (ev.target) {
      const url = ev.target.value;
      if (url === "Mehr") return null;
      window.dispatchEvent(new CustomEvent<string>("redirectMe", { detail: url }));
    }
  };

  render() {
    const _statePackage = { ...this.state, ...this.AppFunctions };

    const _nachrichtenProps = {
      ...this.AppFunctions,
      appReservierungen: this.state.appReservations,
      appDisplayMode: this.state.appDisplayMode,
      appIsLoading: this.state.appIsLoading,
    };

    const _orgaProps = { appSummary: this.state.appSummary, getSummary: this._appGetSummary };
    return (
      <>
        {this.state.isLoadingApp ? (
          <div className="inline-flex flex-col w-full h-full justify-center items-center content-start min-h-0 leading-normal">
            <h2 className="text-lg font-semibold tracking-wide text-white">App wird eingerichtet</h2>
            <span className="animate-pulse text-blue-50 text-sm mt-1.5">Gleich ist es soweit...</span>
          </div>
        ) : (
          <div
            id="App"
            className="App relative bg-gray-100 flex flex-col w-full h-full min-h-0 overflow-hidden antialiased leading-normal"
          >
            <PurgeExceptions />
            <div className="fixed inset-0 w-0 h-0 hidden w-1/2 w-1/3 w-1/4 w-1/5 w-1/6 w-1/12 min-w-0 max-w-0 min-h-0 max-h-0 scale-105 scale-110 scale-125"></div>
            <ModalHandler.ModalBase />

            <InstallPrompt
              appColors={this.state.appColors}
              logo={logo}
              neverShowAgain={this._handleInstallNeverShow}
              afterInstallation={this._handleAfterInstall}
              shouldDisplay={this.state.appIsStandalone ? false : this.state.appShouldShowInstallPrompt}
            ></InstallPrompt>
            <AppNavBar
              appVersion={appVersion}
              appCauses={this.state.appCauses}
              appColSize={this.state.appColSize}
              appRestaurantId={this.state.appRestaurantId}
              appColors={this.state.appColors}
              appDisplayMode={this.state.appDisplayMode}
              appHeadTitle={this.state.appHeadTitle}
              appIsCached={this.state.appIsCached}
              appIsUpdateAvailable={this.state.appIsUpdateAvailable}
              appReservationDate={this.state.appReservationDate}
              appSetOverview={this.AppFunctions.appSetOverview}
              appShowNotification={this.AppFunctions.appShowNotification}
              appWebSocket={this.state.appWebSocket}
              appSize={this.state.appSize}
              appInitialiseWebSocket={this.AppFunctions.appInitialiseWebsocket}
              setAppState={this.AppFunctions.setAppState}
              setNewReservationPrimer={this.AppFunctions.setNewReservationPrimer}
            >
              <AppLink
                {...this.state.appColors}
                to={this.state.appDisplayMode !== "Tablet" ? "/Nachrichten/" : "/Reservierung/2"}
              >
                <span className="relative flex-shrink-0">
                  <this._renderNotificationNumber></this._renderNotificationNumber>
                  Nachrichten
                </span>
              </AppLink>
              <AppLink
                {...this.state.appColors}
                to={this.state.appDisplayMode !== "Tablet" ? "/Reservierung/" : "/Reservierung/3"}
              >
                Reservierungen
              </AppLink>
              <AppLink {...this.state.appColors} to="/Organisation/">
                Organisation
              </AppLink>
              <AppLink {...this.state.appColors} to="/Veranstaltungen/">
                Veranstaltungen
              </AppLink>
              <AppLink {...this.state.appColors} to={Information.Routes.Root}>
                Informationen
              </AppLink>

              {this.state.appDisplayMode !== "Mobile" ? (
                <select
                  className={`inline-flex px-4 justify-center items-center no-underline tracking-normal bg-transparent text-xl md:text-xs text-primary-100 md:text-primary-200 hover:text-primary-50 h-full overflow-hidden focus:text-gray-50 bg-primary-400 shadow-inner`}
                  onChange={this._handleMenuChange}
                  value="Mehr"
                >
                  <option disabled>Mehr</option>

                  {this.state.appRestaurantId === "UnitTesting" ? <option value="/FetchToy/">FetchToy</option> : null}
                  <option value="/Events/">Events</option>
                  <option value="/Protocol/">Protokoll</option>
                  <option value="/Export/">Export</option>
                  <option value="/Absprache/">Absprache</option>
                  <option value="/DebugWS/">DebugWS</option>
                  <option value={StorageRoutes.root}>Storage</option>
                  {this.state.appRestaurantId === "UnitTesting" ? <option value="/Test/">Test</option> : null}
                </select>
              ) : (
                <>
                  {this.state.appRestaurantId === "UnitTesting" ? (
                    <AppLink {...this.state.appColors} to="/FetchToy/">
                      FetchToy
                    </AppLink>
                  ) : null}
                  <AppLink {...this.state.appColors} to="/Protocol/">
                    Protokoll
                  </AppLink>
                  <AppLink {...this.state.appColors} to="/Export/">
                    Export
                  </AppLink>
                  <AppLink {...this.state.appColors} to="/Absprache/">
                    Absprache
                  </AppLink>
                  <AppLink {...this.state.appColors} to="/DebugWS/">
                    DebugWS
                  </AppLink>
                  <AppLink {...this.state.appColors} to={StorageRoutes.root}>
                    Storage
                  </AppLink>
                  <AppLink {...this.state.appColors} to={Information.Routes.Root}>
                    Informationen
                  </AppLink>
                  {this.state.appRestaurantId === "UnitTesting" ? (
                    <>
                      <AppLink {...this.state.appColors} to="/Events/">
                        Events
                      </AppLink>
                      <AppLink {...this.state.appColors} to="/Test/">
                        Test
                      </AppLink>
                    </>
                  ) : null}
                </>
              )}
            </AppNavBar>

            <div
              className={`absolute flex flex-1 flex-col flex-shrink-0 w-full h-full z-40 shadow-md top-0 overflow-hidden`}
              style={this.state ? (this.state.appDrawerIsVisible ? _drawerVisibleStyle : _drawerInvisibleStyle) : {}}
            >
              {this.state.appOverviewVisible && this.state.appDisplayMode !== "Tablet" ? (
                <Overview
                  appIsStandalone={this.state.appIsStandalone}
                  appColors={this.state.appColors}
                  date={this._getOverviewDate(this.state.appReservationDate)}
                  day={this._getOverviewDay(this.state.appReservationDate)}
                  handleClose={this._handleCloseOverview}
                  personAmount={3}
                  reservationAmount={this.state.appIsLoading["Reservations"] ? "?" : this.state.appReservations.length}
                  transition={defaultTransition}
                  tableList={this.state.appTables}
                  appReservations={this.state.appReservations}
                >
                  <ViewLoader
                    props={{
                      appIsStandalone: this.state.appIsStandalone,
                      selectedDate: this.state.appReservationDate.toISOString().slice(0, 10),
                      appColors: this.state.appColors,
                      getSummary: this._overViewGetSummary,
                      selectDate: this._handleOverviewDateSelect,
                      getCreatedSummary: this._handleCreatedSummary,
                      preCreatedSummary: this.state.appSummary || undefined,
                      size: this.state.appColSize ? ((this.state.appColSize + "") as any) : undefined,
                    }}
                    componentKey="DateTable"
                  />
                </Overview>
              ) : null}
            </div>

            <AppRouteSwitch>
              <Route
                key="ReservierungGeneric"
                index
                element={
                  this.state.appDisplayMode === "Tablet" ? (
                    <DelayedNavigate to={"/Reservierung/3"} />
                  ) : (
                    <DelayedNavigate to={"/Reservierung"} />
                  )
                }
              />
              <Route
                key="Reservierung"
                path={"/Reservierung/*"}
                element={
                  <ErrorBoundary>
                    {this.state.appDisplayMode === "Web" ? <ReservierungWeb {..._statePackage} /> : null}
                    {this.state.appDisplayMode === "Mobile" ? <ReservierungMobile {..._statePackage} /> : null}
                    {this.state.appDisplayMode === "Tablet" ? (
                      <Routes>
                        <Route path="/:activeView/:causeToSelect" element={<ReservierungTablet {..._statePackage} />} />
                        <Route path="/:activeView" element={<ReservierungTablet {..._statePackage} />} />
                        <Route path="/" element={<ReservierungTablet {..._statePackage} />} />
                      </Routes>
                    ) : null}
                  </ErrorBoundary>
                }
              />

              <Route
                key="Nachrichten"
                path="/Nachrichten/*"
                element={
                  this.state.appDisplayMode !== "Tablet" ? (
                    <ErrorBoundary>
                      <ViewLoader props={_nachrichtenProps} componentKey="NachrichtenView" />
                    </ErrorBoundary>
                  ) : (
                    <DelayedNavigate to={"/Reservierung/2"} />
                  )
                }
              />
              <Route
                key="FetchToy"
                path="/FetchToy/*"
                element={
                  <ErrorBoundary>
                    <ViewLoader props={_statePackage} componentKey="FetchToy" />
                  </ErrorBoundary>
                }
              />
              <Route
                key="Events"
                path="/Events/*"
                element={
                  <ErrorBoundary>
                    <ViewLoader props={_statePackage} componentKey="Events" />
                  </ErrorBoundary>
                }
              />

              <Route
                key="Protocol"
                path="/Protocol/*"
                element={
                  <ErrorBoundary>
                    <ViewLoader props={_statePackage} componentKey="Protokoll"></ViewLoader>
                  </ErrorBoundary>
                }
              />

              <Route
                key="Organisation"
                path="/Organisation/*"
                element={
                  <ErrorBoundary>
                    <ViewLoader props={_orgaProps} componentKey="OrganisationPageView"></ViewLoader>
                  </ErrorBoundary>
                }
              />

              <Route
                key="Test"
                path="/Test/*"
                element={
                  <ErrorBoundary>
                    <Test reservierung={{} as any} />
                  </ErrorBoundary>
                }
              />
              <Route
                key="DebugWS"
                path="/DebugWS/*"
                element={
                  <ErrorBoundary>
                    <DebugConnectionView />
                  </ErrorBoundary>
                }
              />
              <Route
                key="Storage"
                path={StorageRoutes.root + "/*"}
                element={
                  <ErrorBoundary>
                    <StorageMainView />
                  </ErrorBoundary>
                }
              />
              <Route
                key="Veranstaltung"
                path={"/Veranstaltungen/*"}
                element={
                  <ErrorBoundary>
                    <ViewLoader props={_statePackage} componentKey="VeranstaltungSimpleView" />
                  </ErrorBoundary>
                }
              />
              <Route
                key={Information.Key}
                path={Information.Routes.Root + "/*"}
                element={
                  <ErrorBoundary>
                    <ViewLoader props={_statePackage} componentKey={Information.Key} />
                  </ErrorBoundary>
                }
              />

              <Route
                key="Export"
                path="/Export/*"
                element={
                  <ErrorBoundary>
                    <ExportMainView date={this.state.appReservationDate} />
                  </ErrorBoundary>
                }
              />
              <Route
                key="Absprache"
                path="/Absprache/*"
                element={
                  <ErrorBoundary>
                    <AbspracheMainView
                      date={this.state.appReservationDate}
                      reservierungen={this.state.appReservations}
                      setDate={this._setAppDate}
                    />
                  </ErrorBoundary>
                }
              />
              <Route key="Error" path="/*" element={<DelayedNavigate to="/Reservierung" />} />
            </AppRouteSwitch>
          </div>
        )}
      </>
    );
  }
}

const trackedApp = withAITracking(
  reactPlugin,
  App,
  "Main App",
  "inline-flex flex-col w-full h-full min-h-0 min-w-0 max-h-full max-w-full justify-start items-start content-start leading-0"
);

export default trackedApp;
